import React, { PureComponent } from 'react'
import {
    MessageBarType,
    TextField,
    ICommandBarItemProps,
    TooltipHost,
    DirectionalHint,
    TooltipDelay,
    Text,
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    DefaultButton,
    Pivot,
    PivotItem,
    IconButton,
    MessageBar,
    Link as FluentLink,
} from '@fluentui/react'
import history from 'helpers/history'
import { Link } from 'react-router-dom'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import Client, { ErrorClient } from 'requests/objects/client'
import EProfile from 'types/profile'
import NewParentCompanyModal from 'components/pages/clients/[id]/index/newParentCompanyModal'
import setPageTitle from 'helpers/methods/pageTitle'
import Time from 'helpers/methods/time'
import EventFileInput from 'components/pages/clients/[id]/index/contacts/[id]/evenements/eventFileInput'
import SortableList from 'components/pages/clients/[id]/index/sortableList'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdClients extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.clientId,
            /** @type {Client} Element find from API */
            item: new Client(),
            /** @type {Client} Initial element fond from API. Used to refresh */
            iniItem: new Client(),
            /** @type {ErrorClient} Errors */
            errorField: new ErrorClient(),
            /** @type {import('@fluentui/react').IColumn[]} */
            contactsColumns: [
                {
                    key: 'lastname',
                    name: 'Nom',
                    fieldName: 'lastname',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => {
                        const content = `${item.lastname} ${item.firstname}`
                        return item.hasLeft ? <del>{content}</del> : content
                    },
                },
                {
                    key: 'subDivision.name',
                    name: 'Sous-famille',
                    fieldName: 'subDivision.name',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item.hasLeft ? <del>{item.subDivision?.name}</del> : item.subDivision?.name),
                },
                {
                    key: 'metier.name',
                    name: 'Metier',
                    fieldName: 'metier.name',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item.hasLeft ? <del>{item.metier?.name}</del> : item.metier?.name),
                },
                {
                    key: 'landlinePhone',
                    name: 'Téléphone',
                    fieldName: 'landlinePhone',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => {
                        const content = [item.landlinePhone, item.mobilePhone].filter(x => x).join(', ')
                        return item.hasLeft ? <del>{content}</del> : content
                    },
                },
            ],
            /** @type {import('@fluentui/react').IColumn[]} */
            missionsColumns: [
                {
                    key: 'ref',
                    name: 'Référence',
                    fieldName: 'ref',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                },
                {
                    key: 'name',
                    name: 'Nom',
                    fieldName: 'name',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                },
            ],
            /** @type {boolean} isNewParentCompanyModalVisible */
            isNewParentCompanyModalVisible: false,
            /** @type {number} commercialInformationProcessOptionId of the ongoing commercial information process */
            commercialInformationProcessOptionId: null,
            /** @type {number} commercialInformationId of the ongoing commercial information process */
            commercialInformationId: null,
            /** @type {string} Current pivot to display */
            pivotSelectedKey: 'Données',
            eventClientsColumns: [
                {
                    key: 'hasTodo',
                    name: 'A TODO',
                    fieldName: 'hasTodo',
                    minWidth: 100,
                    maxWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    onRender: item => (item.hasTodo ? 'Oui' : 'Non'),
                },
                {
                    key: 'comment',
                    name: 'Commentaire',
                    fieldName: 'comment',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text
                            title={item?.comment}
                            block
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}
                        >
                            {item?.comment}
                        </Text>
                    ),
                },
                {
                    key: 'libelle',
                    name: 'Libellé',
                    fieldName: 'eventType.name',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {item['eventType.name'] || ''}
                        </Text>
                    ),
                },
                {
                    key: 'missionId',
                    name: 'Mission',
                    fieldName: 'mission.ref',
                    minWidth: 100,
                    maxWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item['mission.missionId'] ? (
                        <Link
                            to={{
                                pathname: `/missions/${item['mission.missionId']}`,
                            }}
                        >
                            {item['mission.ref'] || ''}
                        </Link>
                    ) : ''),
                },
                {
                    key: 'updatedAt',
                    name: 'Mise à jour',
                    fieldName: 'updatedAt',
                    minWidth: 150,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item?.updatedAt ? Time(new Date(item.updatedAt)).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                },
                {
                    key: 'todoDate',
                    name: 'Date TODO',
                    fieldName: 'todoDate',
                    minWidth: 100,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item?.todoDate ? Time(new Date(item.todoDate.toString())).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                },
                {
                    key: 'userCollaborator',
                    name: 'Collaborateur',
                    fieldName: 'userCollaborator.lastname',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    onRender: item => [item['userCollaborator.lastname'], item['userCollaborator.firstname']].filter(x => x).join(' ').trim() || 'Inconnu',
                },
                {
                    key: 'action',
                    name: '',
                    minWidth: 150,
                    maxWidth: 150,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item.eventTypeId === 35 // ENVOI CONTRAT COLLABORATION
                        ? (
                            <EventFileInput
                                label=""
                                isReadOnly
                                contactId={item.contactId}
                                eventClientId={item.eventClientId}
                                // eslint-disable-next-line react/destructuring-assignment
                                handler={this.props.contactsHandler}
                                updateItem={() => null}
                                item={item}
                            />
                        )
                        : null),
                },
            ],
        }

        this.submitInput = React.createRef()
        this.toolTipRef = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous state
     */
    componentDidUpdate(prevProps, prevState) {
        const { match, setCommand } = this.props
        const { pivotSelectedKey } = this.state

        if (match?.params?.clientId !== prevProps?.match?.params?.clientId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (prevState.pivotSelectedKey !== pivotSelectedKey) {
            this.setupCommandBar()
            setCommand(this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.clientsHandlerRemoveById?.cancel()
        this.clientsHandlerGetById?.cancel()
        this.clientsHandlerUpsert?.cancel()
        this.clientsHandlerUpsertWithProcess?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Client ${match?.params?.clientId ? item.name || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie client', key: 'client' },
            { text: 'Tous les clients', key: 'all-client', href: '/clients' },
            { text: mainTitle, key: 'one-client', isCurrentItem: true },
        ])
        setTitle('Clients')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, clientsHandler, setMessageBar, me,
        } = this.props
        const { pivotSelectedKey } = this.state

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false, pivotSelectedKey: 'Données' }, () => setCommand(this.commandEdit)),
                disabled: false,
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
        ].filter(x => x.isVisible)

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorClient(),
                            pivotSelectedKey: 'Données',
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.clientId,
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer le client',
                        subTitle: 'Êtes-vous certain de vouloir supprimer le client ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.clientsHandlerRemoveById = clientsHandler.removeById(match?.params?.clientId)
                                    await this.clientsHandlerRemoveById.fetch()
                                    history.push('/clients')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                isVisible: ['Données'].includes(pivotSelectedKey),
                disabled: !match?.params?.clientId || me.profileId !== EProfile.Administrator,
            },
        ].filter(x => x.isVisible)
    }

    setupProcessData() {
        const { match } = this.props
        const { item } = this.state
        const queryParams = new URLSearchParams(window.location.search)
        const commercialInformationId = queryParams.get('commercialInformationId') ? +queryParams.get('commercialInformationId') : null
        const commercialInformationProcessOptionId = queryParams.get('commercialInformationProcessOptionId')
            ? +queryParams.get('commercialInformationProcessOptionId')
            : null
        const name = queryParams.get('name')

        if (!match?.params?.clientId && commercialInformationId && commercialInformationProcessOptionId && name)
            this.setState({ commercialInformationId, commercialInformationProcessOptionId, item: { ...item, name } })
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, clientsHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.clientId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.client) // If object came from history push with a create
                    this.setState({
                        item: location.state?.client,
                        iniItem: location.state?.client,
                        status: Status.RESOLVED,
                    }, () => {
                        this.setupCommandBar()
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.clientsHandlerGetById = clientsHandler.getById(match?.params?.clientId)
                        const client = await this.clientsHandlerGetById.fetch()
                        this.setState({
                            item: client,
                            iniItem: client,
                            status: Status.RESOLVED,
                        }, () => {
                            this.setupCommandBar()
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Client(),
                    iniItem: new Client(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                    this.setupProcessData()
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, clientsHandler, match, setMessageBar, addParam, location,
        } = this.props
        const { item, commercialInformationId, commercialInformationProcessOptionId } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                if (!match?.params?.clientId && commercialInformationId && commercialInformationProcessOptionId) {
                    this.clientsHandlerUpsertWithProcess = clientsHandler.createWithProcess(
                        item,
                        commercialInformationId,
                        commercialInformationProcessOptionId,
                    )
                    await this.clientsHandlerUpsertWithProcess.fetch()
                    history.push(`/informations-commerciales/${commercialInformationId}`)
                    return
                }

                this.clientsHandlerUpsert = clientsHandler.upsert(item, match?.params?.clientId)
                const client = await this.clientsHandlerUpsert.fetch()

                if (!item.parentCompanyId && client.parentCompanyId)
                    addParam({
                        key: 'parentCompanies',
                        value: {
                            key: client.parentCompany?.parentCompanyId,
                            text: client.parentCompany?.name,
                        },
                    })

                this.setState({
                    item: client,
                    iniItem: client,
                    status: Status.RESOLVED,
                    errorField: new ErrorClient(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.clientId) {
                    history.replace(`/clients/${client.clientId}`)
                    history.push({
                        pathname: `/clients/${client.clientId}/contacts/${NEW_PATH}`,
                        state: {
                            contact: (location.state?.contact ?? {}), // Push data contact. From IC
                        },
                    })
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'Le client à bien été créé. Veuillez ajouter un contact.' })
                }
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorClient>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, contactsColumns, isNewParentCompanyModalVisible,
            commercialInformationId, commercialInformationProcessOptionId, pivotSelectedKey, missionsColumns, eventClientsColumns,
        } = this.state
        const { param, parentCompaniesHandler, addParam } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <>
                {
                    commercialInformationId && commercialInformationProcessOptionId && (
                        <>
                            <MessageBar>
                                Traitement prospect - création du client
                                <FluentLink
                                    as={Link}
                                    to={{
                                        pathname: `/informations-commerciales/${commercialInformationId}`,
                                    }}
                                    target="_self"
                                >
                                    {item.name}
                                </FluentLink>
                            </MessageBar>
                            <br />
                        </>
                    )
                }
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.upsert()
                    }}
                >
                    <Pivot
                        styles={{ itemContainer: { paddingTop: '20px' } }}
                        selectedKey={pivotSelectedKey}
                        onLinkClick={pivotItem => this.setState({ pivotSelectedKey: pivotItem.props.itemKey })}
                    >
                        <PivotItem
                            headerText="Entreprise"
                            itemIcon="PageData"
                            itemKey="Données"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <Card
                                title="Informations"
                                iconName="PageData"
                            >
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Nom"
                                            value={item.name}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, name: newVal?.toUpperCase() } })}
                                            errorMessage={errorField.name}
                                            required
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Siret"
                                            value={item.siret}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, siret: newVal?.toUpperCase() } })}
                                            errorMessage={errorField.siret}
                                            required
                                            minLength={14}
                                            maxLength={14}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Téléphone"
                                            value={item.landlinePhone}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, landlinePhone: newVal } })}
                                            errorMessage={errorField.landlinePhone}
                                            type="phone"
                                            required
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Effectifs"
                                            readOnly={isReadOnly}
                                            options={param.workforces}
                                            selectedKey={item.workforceId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, workforceId: option.key } })}
                                            errorMessage={errorField.workforceId}
                                            required
                                        />
                                    </Columns.Column>
                                </Columns>
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Chiffre d'affaires"
                                            readOnly={isReadOnly}
                                            options={param.turnovers}
                                            selectedKey={item.turnoverId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, turnoverId: option.key } })}
                                            errorMessage={errorField.turnoverId}
                                            required
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Société mère"
                                            readOnly={isReadOnly}
                                            options={param.parentCompanies}
                                            selectedKey={item.parentCompanyId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, parentCompanyId: option.key } })}
                                            errorMessage={errorField.parentCompanyId}
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            onRenderLabel={(props, defaultRender) => (
                                                <div style={{ display: 'flex' }}>
                                                    {defaultRender(props)}
                                                    <TooltipHost
                                                        content="Pour créer une nouvelle société mère, cliquez ici."
                                                        directionalHint={DirectionalHint.topCenter}
                                                        delay={TooltipDelay.zero}
                                                        styles={{
                                                            root: {
                                                                display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                                            },
                                                        }}
                                                        componentRef={this.toolTipRef}
                                                    >
                                                        <IconButton
                                                            iconProps={{ iconName: 'Add' }}
                                                            onClick={() => this.setState({ isNewParentCompanyModalVisible: true })}
                                                            styles={{ root: { marginLeft: '5px' } }}
                                                            disabled={isReadOnly}
                                                        />
                                                    </TooltipHost>
                                                </div>
                                            )}
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            onRenderInput={(props, defaultRender) => (isReadOnly && item.parentCompanyId
                                                ? (
                                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                                        <Link to={`/clients/${item?.clientId}/societe-meres/${item.parentCompanyId}`}>
                                                            {item.parentCompany?.name}
                                                        </Link>
                                                    </Text>
                                                )
                                                : defaultRender(props))}
                                        />
                                    </Columns.Column>
                                </Columns>
                            </Card>
                            <br />
                            <Card
                                title="Adresse"
                                iconName="CityNext"
                            >
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Adresse"
                                            value={item.address}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, address: newVal?.toUpperCase() } })}
                                            errorMessage={errorField.address}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Code postal"
                                            value={item.zipCode}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, zipCode: newVal?.toUpperCase() } })}
                                            errorMessage={errorField.zipCode}
                                            required
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Ville"
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            options={param.cities}
                                            selectedKey={item.cityId}
                                            onChange={(ev, option) => this.setState({ item: { ...item, cityId: option.key } })}
                                            errorMessage={errorField.cityId}
                                            required
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Département"
                                            readOnly={isReadOnly}
                                            options={param.departments}
                                            selectedKey={item.departmentId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, departmentId: option.key } })}
                                            errorMessage={errorField.departmentId}
                                            required
                                        />
                                    </Columns.Column>
                                </Columns>
                            </Card>
                            <br />
                            <Card
                                title="Compléments"
                                iconName="Relationship"
                            >
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Nature"
                                            readOnly={isReadOnly}
                                            options={param.natures}
                                            selectedKey={item.natureId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, natureId: option.key } })}
                                            errorMessage={errorField.natureId}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Secteur"
                                            readOnly={isReadOnly}
                                            options={param.sectors}
                                            selectedKey={item.sectorId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, sectorId: option.key } })}
                                            errorMessage={errorField.sectorId}
                                            required
                                        />
                                    </Columns.Column>
                                </Columns>
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Activité"
                                            value={item.activity}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, activity: newVal?.toUpperCase() } })}
                                            errorMessage={errorField.activity}
                                            multiline
                                            required
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Informations"
                                            value={item.information}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, information: newVal?.toUpperCase() } })}
                                            errorMessage={errorField.information}
                                            multiline
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Site web"
                                            value={item.website}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => this.setState({ item: { ...item, website: newVal } })}
                                            errorMessage={errorField.website}
                                        />
                                    </Columns.Column>
                                </Columns>
                            </Card>
                        </PivotItem>
                        <PivotItem
                            headerText="Contacts"
                            itemIcon="ContactInfo"
                            itemKey="Contacts"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <Card
                                title="Contacts"
                                iconName="ContactInfo"
                            >
                                <DefaultButton
                                    text="Nouveau contact"
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={() => history.push(`/clients/${item?.clientId}/contacts/${NEW_PATH}`)}
                                    disabled={!isReadOnly || !item.clientId}
                                />
                                <br />
                                <br />
                                <ShimmeredDetailsList
                                    items={item.contacts}
                                    columns={contactsColumns}
                                    enableShimmer={status === Status.PENDING}
                                    selectionMode={SelectionMode.none}
                                    onShouldVirtualize={() => true}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderRow={(props, defaultRender) => (
                                        <Link
                                            to={{
                                                pathname: `/clients/${props.item?.clientId}/contacts/${props.item?.contactId}`,
                                            }}
                                        >
                                            {defaultRender(props)}
                                        </Link>
                                    )}
                                />
                                {!item.contacts?.length && status !== Status.IDLE
                                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun contact n'a été trouvé</Text>}
                            </Card>
                        </PivotItem>
                        <PivotItem
                            headerText="Mission(s)"
                            itemIcon="Money"
                            itemKey="Mission(s)"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <Card
                                title="Mission(s)"
                                iconName="FavoriteStarFill"
                            >
                                <ShimmeredDetailsList
                                    items={item.missions}
                                    columns={missionsColumns}
                                    enableShimmer={status === Status.PENDING}
                                    selectionMode={SelectionMode.none}
                                    onShouldVirtualize={() => true}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderRow={(props, defaultRender) => (
                                        <Link
                                            to={{
                                                pathname: `/missions/${props.item?.missionId}`,
                                            }}
                                        >
                                            {defaultRender(props)}
                                        </Link>
                                    )}
                                />
                                {!item.missions?.length && status !== Status.IDLE
                                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucune mission n'a été trouvé</Text>}
                            </Card>
                        </PivotItem>
                        <PivotItem
                            headerText="Évènement(s) Business"
                            itemIcon="Event"
                            itemKey="Evenement(s) Business"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <Card
                                title="Évènement(s) Business"
                                iconName="Event"
                            >
                                <SortableList
                                    columns={eventClientsColumns}
                                    items={item.contactEvents.filter(val => val.hasBusinessMemory)}
                                    status={status}
                                    noDataText="Aucun évènements n'a été trouvé"
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderRow={(props, defaultRender) => (
                                        <Link
                                            // eslint-disable-next-line max-len
                                            to={`/clients/${item?.clientId}/contacts/${props.item?.contactId}/evenements/${props.item?.eventClientId}`}
                                            onClick={ev => {
                                                // eslint-disable-next-line prefer-destructuring
                                                const target = /** @type {HTMLElement} */(ev.target)
                                                if (target.closest('button') || target.nodeName.toLowerCase() === 'button')
                                                    ev.preventDefault()
                                            }}
                                        >
                                            {defaultRender({
                                                ...props,
                                                styles: {
                                                    cell: { display: 'flex', alignSelf: 'center' },
                                                    checkCell: {
                                                        display: 'flex', alignSelf: 'center', height: '100%',
                                                    },
                                                    isMultiline: {
                                                        height: 'auto',
                                                        whiteSpace: 'normal',
                                                    },
                                                },
                                            })}
                                        </Link>
                                    )}
                                />
                            </Card>
                        </PivotItem>
                        <PivotItem
                            headerText="Historique client"
                            itemIcon="Event"
                            itemKey="Evenement(s) Autre"
                            headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                        >
                            <Card
                                title="Historique client"
                                iconName="Event"
                            >
                                <SortableList
                                    columns={eventClientsColumns}
                                    items={item.contactEvents.filter(val => !val.hasBusinessMemory)}
                                    status={status}
                                    noDataText="Aucun évènements n'a été trouvé"
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderRow={(props, defaultRender) => (
                                        <Link
                                            // eslint-disable-next-line max-len
                                            to={`/clients/${item?.clientId}/contacts/${props.item?.contactId}/evenements/${props.item?.eventClientId}`}
                                            onClick={ev => {
                                                // eslint-disable-next-line prefer-destructuring
                                                const target = /** @type {HTMLElement} */(ev.target)
                                                if (target.closest('button') || target.nodeName.toLowerCase() === 'button')
                                                    ev.preventDefault()
                                            }}
                                        >
                                            {defaultRender({
                                                ...props,
                                                styles: {
                                                    cell: { display: 'flex', alignSelf: 'center' },
                                                    checkCell: {
                                                        display: 'flex', alignSelf: 'center', height: '100%',
                                                    },
                                                    isMultiline: {
                                                        height: 'auto',
                                                        whiteSpace: 'normal',
                                                    },
                                                },
                                            })}
                                        </Link>
                                    )}
                                />
                            </Card>
                        </PivotItem>
                    </Pivot>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
                <NewParentCompanyModal
                    isVisible={isNewParentCompanyModalVisible}
                    setIsVisible={isVisible => {
                        this.setState({ isNewParentCompanyModalVisible: isVisible })
                        // Workearound to hide tooltip avec closing modal
                        if (!isVisible)
                            setTimeout(() => this.toolTipRef.current?.dismiss(), 300)
                    }}
                    handler={parentCompaniesHandler}
                    setParentCompanny={parentCompany => {
                        addParam({
                            key: 'parentCompanies',
                            value: { key: parentCompany.parentCompanyId, text: parentCompany.name },
                        })
                        this.setState({ item: { ...item, parentCompany, parentCompanyId: parentCompany.parentCompanyId } })
                    }}
                />
            </>
        )
    }
}

IdClients.prototype.isValidDate = isValidDate
