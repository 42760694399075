import React, { PureComponent } from 'react'
import {
    MessageBarType,
    TextField,
    ICommandBarItemProps,
    Pivot,
    PivotItem,
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    Text,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import Candidate, { ErrorCandidate } from 'requests/objects/candidate'
import Time from 'helpers/methods/time'
import EProfile from 'types/profile'
import CvInput from 'components/pages/candidats/[id]/cvInput'
import { Link } from 'react-router-dom'
import FileInput from 'components/inputs/fileInput'
import setPageTitle from 'helpers/methods/pageTitle'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import SortableList from 'components/pages/clients/[id]/index/sortableList'

/** @type {import('@fluentui/react').IColumn[]} Columns displayed */
const initcandidaciesColumns = [
    {
        key: 'boardColumnName',
        name: 'Statut Candidature',
        fieldName: 'boardColumn.name',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        /**
         * @param {Candidacy} item Item
         * @returns {string} Value
         */
        onRender: item => {
            if (item.isDeleted)
                return 'Supprimée'
            if (!item.missionId)
                return 'Spontanée'
            if (item.isInterviewCancel)
                return 'Entretien annulé'
            if (item.isRejected)
                return 'Rejeté'
            return item.boardColumn?.name
        },
    },
    {
        key: 'updatedAt',
        name: 'Mise à jour',
        fieldName: 'updatedAt',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text>
                {(item.updatedAt ? Time(item.updatedAt).getCleanDate({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }) : '')}
            </Text>
        ),
    },
    {
        key: 'mission.ref',
        name: 'Référence mission',
        fieldName: 'mission.ref',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: item => item.mission?.ref ?? '',
    },
    {
        key: 'mission.name',
        name: 'Intitulé de poste',
        fieldName: 'mission.name',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: item => item.mission?.name ?? '',
    },
    {
        key: 'comment',
        name: 'Commentaire',
        fieldName: 'comment',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text
                title={item?.comment}
                block
                style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}
            >
                {item?.comment}
            </Text>
        ),
    },
    {
        key: 'mission.client.name',
        name: 'Entreprise',
        fieldName: 'mission.client.name',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: item => item.mission?.client?.name ?? '',
    },
]

/** @type {import('@fluentui/react').IColumn[]} Columns displayed */
const inithuntsColumns = [
    {
        key: 'company',
        name: 'Société à chasser',
        fieldName: 'company',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
    },
    {
        key: 'boardColumnName',
        name: 'Statut Candidature',
        fieldName: 'boardColumn.name',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        /**
         * @param {Hunt} item Item
         * @returns {string} Value
         */
        onRender: item => {
            if (item.isDeleted)
                return 'Supprimée'
            if (!item.missionId)
                return 'Spontanée'
            if (item.isInterviewCancel)
                return 'Entretien annulé'
            if (item.isRejected)
                return 'Rejeté'
            return item.boardColumn?.name
        },
    },
    {
        key: 'updatedAt',
        name: 'Mise à jour',
        fieldName: 'updatedAt',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text>
                {(item.updatedAt ? Time(item.updatedAt).getCleanDate({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }) : '')}
            </Text>
        ),
    },
    {
        key: 'mission.ref',
        name: 'Référence mission',
        fieldName: 'mission.ref',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: item => item.mission?.ref ?? '',
    },
    {
        key: 'mission.name',
        name: 'Intitulé de poste',
        fieldName: 'mission.name',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: item => item.mission?.name ?? '',
    },
    {
        key: 'comment',
        name: 'Commentaire',
        fieldName: 'comment',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text
                title={item?.comment}
                block
                style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}
            >
                {item?.comment}
            </Text>
        ),
    },
    {
        key: 'mission.client.name',
        name: 'Entreprise',
        fieldName: 'mission.client.name',
        minWidth: 125,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onRender: item => item.mission?.client?.name ?? '',
    },
]
/** @type {import('@fluentui/react').IColumn[]} Columns displayed */
const initeventCandidatesColumn = [
    {
        key: 'hastTodo',
        name: 'A TODO',
        fieldName: 'hasTodo',
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        onRender: item => (item.hasTodo ? 'Oui' : 'Non'),
    },
    {
        key: 'comment',
        name: 'Commentaire',
        fieldName: 'comment',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text
                title={item?.comment}
                block
                style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}
            >
                {item?.comment}
            </Text>
        ),
    },
    {
        key: 'missionId',
        name: 'Mission',
        fieldName: 'mission.ref',
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (item['mission.missionId'] ? (
            <Link
                to={{
                    pathname: `/missions/${item['mission.missionId']}`,
                }}
            >
                {item['mission.ref'] || ''}
            </Link>
        ) : ''),
    },
    {
        key: 'candidacyId',
        name: 'Candidature',
        fieldName: 'candidate.lastname',
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (item?.candidacyId
            ? (
                <Link
                    to={{
                        pathname: `/candidats/${item?.candidateId}/candidatures/${item?.candidacyId}`,
                    }}
                >
                    {[item['candidate.lastname'], item['candidate.firstname']].filter(x => x).join(' ').trim() || 'Inconnu'}
                </Link>
            )
            : [item['candidate.lastname'], item['candidate.firstname']].filter(x => x).join(' ').trim() || 'Inconnu'),
    },
    {
        key: 'updatedAt',
        name: 'Mise à jour',
        fieldName: 'updatedAt',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text>
                {(item.updatedAt ? Time(new Date(item.updatedAt)).getCleanDate({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }) : '')}
            </Text>
        ),
    },
    {
        key: 'todoDate',
        name: 'Date TODO',
        fieldName: 'todoDate',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (
            <Text>
                {(item.todoDate ? Time(new Date(item.todoDate.toString())).getCleanDate({
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                }) : '')}
            </Text>
        ),
    },
]

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdCandidates extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.candidateId,
            /** @type {Candidate} Element find from API */
            item: new Candidate(),
            /** @type {Candidate} Initial element fond from API. Used to refresh */
            iniItem: new Candidate(),
            /** @type {ErrorCandidate} Errors */
            errorField: new ErrorCandidate(),
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            candidaciesColumns: initcandidaciesColumns,
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            huntsColumns: inithuntsColumns,
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            eventCandidatesColumn: initeventCandidatesColumn,
            /** @type {string} Current pivot to display */
            pivotSelectedKey: 'Données',
            /** @type {globalThis.File} tempFile */
            tempFile: null,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { match, setCommand } = this.props
        const { item, pivotSelectedKey } = this.state

        if (match?.params?.candidateId !== prevProps?.match?.params?.candidateId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (prevState.item?.divisionId !== item?.divisionId && item?.divisionId && prevState.item?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                item: {
                    ...truePrevState.item,
                    subDivisionId: null,
                    metier1Id: null,
                    metier2Id: null,
                },
            }))

        if (prevState.item?.subDivisionId !== item?.subDivisionId && item?.subDivisionId && prevState.item?.subDivisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                item: {
                    ...truePrevState.item,
                    metier1Id: null,
                    metier2Id: null,
                },
            }))

        if (prevState.pivotSelectedKey !== pivotSelectedKey) {
            this.setupCommandBar()
            setCommand(this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.candidatesHandlerRemoveById?.cancel()
        this.candidatesHandlerGetById?.cancel()
        this.candidatesHandlerUpsert?.cancel()
        this.handlerUploadFile?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Candidat ${match?.params?.candidateId ? [item.lastname, item.firstname].filter(x => x).join(' ').trim() || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie candidat', key: 'candidat' },
            { text: 'Tous les candidats', key: 'all-candidat', href: '/candidats' },
            {
                text: mainTitle,
                key: 'one-candidat',
                isCurrentItem: true,
            },
        ])
        setTitle('Candidats')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, candidatesHandler, setMessageBar, me,
        } = this.props
        const { pivotSelectedKey } = this.state

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false, pivotSelectedKey: 'Données' }, () => setCommand(this.commandEdit)),
                disabled: false,
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
        ].filter(x => x.isVisible)

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorCandidate(),
                            pivotSelectedKey: 'Données',
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                isVisible: ['Données'].includes(pivotSelectedKey),
                disabled: !match?.params?.candidateId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer le candidat',
                        subTitle: 'Êtes-vous certain de vouloir supprimer le candidat ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.candidatesHandlerRemoveById = candidatesHandler.removeById(match?.params?.candidateId)
                                    await this.candidatesHandlerRemoveById.fetch()
                                    history.push('/candidats')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                isVisible: ['Données'].includes(pivotSelectedKey),
                disabled: !match?.params?.candidateId || me.profileId !== EProfile.Administrator,
            },
        ].filter(x => x.isVisible)
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, candidatesHandler, setMessageBar,
        } = this.props
        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.candidateId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.candidate) // If object came from history push with a create
                    this.setState({
                        item: location.state?.candidate,
                        iniItem: location.state?.candidate,
                        status: Status.RESOLVED,
                    }, () => {
                        this.setupCommandBar()
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.candidatesHandlerGetById = candidatesHandler.getById(match?.params?.candidateId)
                        const candidate = await this.candidatesHandlerGetById.fetch()
                        this.setState({
                            item: candidate,
                            iniItem: candidate,
                            status: Status.RESOLVED,
                        }, () => {
                            this.setupCommandBar()
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Candidate(),
                    iniItem: new Candidate(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, candidatesHandler, match, setMessageBar,
        } = this.props
        const { item, tempFile } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.candidatesHandlerUpsert = candidatesHandler.upsert(item, match?.params?.candidateId)
                const candidate = await this.candidatesHandlerUpsert.fetch()

                if (tempFile)
                    try {
                        setMessageBar({ isDisplayed: false })
                        this.handlerUploadFile = candidatesHandler.uploadFileCv(
                            candidate.candidateId,
                            tempFile,
                        )
                        candidate.fileCv = await this.handlerUploadFile.fetch()
                        setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté" })
                    } catch {
                        setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté mais l'ajout du CV a échoué" })
                    }

                this.setState({
                    item: candidate,
                    iniItem: candidate,
                    status: Status.RESOLVED,
                    errorField: new ErrorCandidate(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.candidateId)
                    history.replace(`/candidats/${candidate.candidateId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorCandidate>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, candidaciesColumns, pivotSelectedKey, eventCandidatesColumn, huntsColumns, tempFile,
        } = this.state
        const { param, match, candidatesHandler } = this.props

        if (status === Status.PENDING)
            return <Loader />

        const isNew = !match?.params?.candidateId
        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Pivot
                    styles={{ itemContainer: { paddingTop: '20px' } }}
                    selectedKey={pivotSelectedKey}
                    onLinkClick={pivotItem => this.setState({ pivotSelectedKey: pivotItem.props.itemKey })}
                >
                    <PivotItem
                        headerText="Données"
                        itemIcon="PageData"
                        itemKey="Données"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <Card
                            title="Informations"
                            iconName="PageData"
                        >
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Prénom"
                                        value={item.firstname}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, firstname: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.firstname}
                                        required
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Nom"
                                        value={item.lastname}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, lastname: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.lastname}
                                        required
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Email"
                                        value={item.email}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, email: newVal } })}
                                        errorMessage={errorField.email
                                            // eslint-disable-next-line react/no-danger
                                            ? <span dangerouslySetInnerHTML={{ __html: errorField.email }} />
                                            : null}
                                        type="email"
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label={`Date de naissance${item.birthdate ? ` (${Time(item.birthdate).getAge()} ans)` : ''}`}
                                        value={item.birthdate ? Time(item.birthdate).getLocaleDateString() : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({
                                            item: { ...item, birthdate: this.isValidDate(new Date(newVal)) ? new Date(newVal) : '' },
                                        })}
                                        errorMessage={errorField.birthdate}
                                        type="date"
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Civilité"
                                        readOnly={isReadOnly}
                                        options={param.civilities}
                                        selectedKey={item.civilityId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, civilityId: option.key } })}
                                        errorMessage={errorField.civilityId}
                                        required={!isNew}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Téléphone mobile"
                                        value={item.mobilePhone}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, mobilePhone: newVal } })}
                                        errorMessage={errorField.mobilePhone}
                                        type="phone"
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Téléphone fixe"
                                        value={item.landlinePhone}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, landlinePhone: newVal } })}
                                        errorMessage={errorField.landlinePhone}
                                        type="phone"
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    {item.candidateId
                                        ? (
                                            <CvInput
                                                isReadOnly={isReadOnly}
                                                id={item.candidateId}
                                                handler={candidatesHandler}
                                                updateItem={fileCv => this.setState(prevState => ({
                                                    item: { ...prevState.item, fileCv },
                                                    iniItem: { ...prevState.iniItem, fileCv },
                                                }))}
                                                item={item}
                                            />
                                        )
                                        : (
                                            <FileInput
                                                label="CV"
                                                labelUpload="Télécharger le cv"
                                                isReadOnly={false}
                                                isDisabled={status === Status.PENDING}
                                                fileName={tempFile?.name}
                                                tooltipContent=""
                                                onDownload={() => null}
                                                onUpload={file => Promise.resolve(this.setState({ tempFile: file }))}
                                                onDelete={() => Promise.resolve(this.setState({ tempFile: null }))}
                                            />
                                        )}
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Entreprise actuelle"
                                        value={item.company}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, company: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.company}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Poste actuel"
                                        value={item.currentPosition}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, currentPosition: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.currentPosition}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Profil linkedin"
                                        value={item.linkedinProfileUrl}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, linkedinProfileUrl: newVal } })}
                                        errorMessage={errorField.linkedinProfileUrl}
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        onRenderInput={(props, defaultRender) => (isReadOnly && item.linkedinProfileUrl
                                            ? (
                                                <Text
                                                    styles={{
                                                        root: {
                                                            padding: '6px 8px 0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                                                        },
                                                    }}
                                                >
                                                    <a
                                                        href={item.linkedinProfileUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {item.linkedinProfileUrl}
                                                    </a>
                                                </Text>
                                            )
                                            : defaultRender(props))}
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Adresse"
                            iconName="CityNext"
                        >
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Ville"
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        options={param.cities}
                                        selectedKey={item.cityId}
                                        onChange={(ev, option) => this.setState({
                                            item: {
                                                ...item,
                                                cityId: option.key,
                                                zipCode: option.text.substr(option.text.lastIndexOf('(') + 1).slice(0, -1),
                                            },
                                        })}
                                        errorMessage={errorField.cityId}
                                        required={!isNew}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Code postal"
                                        value={item.zipCode}
                                        readOnly
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, zipCode: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.zipCode}
                                        required={!isNew}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Département"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.departmentId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, departmentId: option.key } })}
                                        errorMessage={errorField.departmentId}
                                        required={!isNew}
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Compléments"
                            iconName="Relationship"
                        >
                            <Columns>
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label="Mobilité 1"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.department1Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, department1Id: option.key } })}
                                        errorMessage={errorField.department1Id}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label="Mobilité 2"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.department2Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, department2Id: option.key } })}
                                        errorMessage={errorField.department2Id}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label="Mobilité 3"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.department3Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, department3Id: option.key } })}
                                        errorMessage={errorField.department3Id}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label="Mobilité 4"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.department4Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, department4Id: option.key } })}
                                        errorMessage={errorField.department4Id}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-fifth">
                                    <FilteredVirtualCombobox
                                        label="Mobilité 5"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.department5Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, department5Id: option.key } })}
                                        errorMessage={errorField.department5Id}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Famille de poste"
                                        readOnly={isReadOnly}
                                        options={param.divisions}
                                        selectedKey={item.divisionId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, divisionId: option.key } })}
                                        errorMessage={errorField.divisionId}
                                        required={!isNew}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Sous-famille"
                                        readOnly={isReadOnly}
                                        options={param.subDivisions.filter(x => !item.divisionId || x.divisionId === item.divisionId)}
                                        selectedKey={item.subDivisionId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, subDivisionId: option.key } })}
                                        errorMessage={errorField.subDivisionId}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Metier 1"
                                        readOnly={isReadOnly}
                                        options={param.metiers.filter(x => !item.subDivisionId || x.subDivisionId === item.subDivisionId)}
                                        selectedKey={item.metier1Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, metier1Id: option.key } })}
                                        errorMessage={errorField.metier1Id}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Metier 2"
                                        readOnly={isReadOnly}
                                        options={param.metiers.filter(x => !item.subDivisionId || x.subDivisionId === item.subDivisionId)}
                                        selectedKey={item.metier2Id}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, metier2Id: option.key } })}
                                        errorMessage={errorField.metier2Id}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Statut du candidat"
                                        defaultValue={item.candidateStatus?.name || ''}
                                        readOnly
                                        borderless
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Rémunération"
                                        value={item.remuneration || ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, remuneration: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.remuneration}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter" />
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Informations sur le candidat"
                            iconName="WebAppBuilderFragment"
                        >
                            <Columns>
                                <Columns.Column size="half">
                                    <TextField
                                        label="Informations"
                                        value={item.information}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, information: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.information}
                                        multiline
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                    </PivotItem>
                    <PivotItem
                        headerText="Candidatures"
                        itemIcon="ContactInfo"
                        itemKey="Candidatures"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <Card
                            title="Candidatures"
                            iconName="ContactInfo"
                        >
                            <ShimmeredDetailsList
                                items={item.candidacies}
                                columns={candidaciesColumns}
                                enableShimmer={status === Status.PENDING}
                                selectionMode={SelectionMode.none}
                                onShouldVirtualize={() => true}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                                // eslint-disable-next-line react/no-unstable-nested-components
                                onRenderRow={(props, defaultRender) => (
                                    <Link
                                        to={{
                                            pathname: `/candidats/${props.item?.candidateId}/candidatures/${props.item?.candidacyId}`,
                                            // state: {
                                            //     candidate: iniItems.find(x => x.candidateId === props.item?.candidateId),
                                            // },
                                        }}
                                    >
                                        {defaultRender(props)}
                                    </Link>
                                )}
                            />
                            {!item.candidacies?.length && status !== Status.IDLE
                                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucune candidature n'a été trouvée</Text>}
                        </Card>
                        <br />
                        <Card
                            title="Chasses"
                            iconName="ContactInfo"
                        >
                            <ShimmeredDetailsList
                                items={item.hunts}
                                columns={huntsColumns}
                                enableShimmer={status === Status.PENDING}
                                selectionMode={SelectionMode.none}
                                onShouldVirtualize={() => true}
                                layoutMode={DetailsListLayoutMode.justified}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                                // eslint-disable-next-line react/no-unstable-nested-components
                                onRenderRow={(props, defaultRender) => (
                                    <Link
                                        to={{
                                            pathname: `/candidats/${props.item?.candidateId}/chasses/${props.item?.huntId}`,
                                            // state: {
                                            //     candidate: iniItems.find(x => x.candidateId === props.item?.candidateId),
                                            // },
                                        }}
                                    >
                                        {defaultRender(props)}
                                    </Link>
                                )}
                            />
                            {!item.hunts?.length && status !== Status.IDLE
                                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucune chasse n'a été trouvée</Text>}
                        </Card>
                    </PivotItem>
                    <PivotItem
                        headerText="Évènements"
                        itemIcon="Event"
                        itemKey="Événements"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <Card
                            title="Évènements"
                            iconName="Event"
                        >
                            <SortableList
                                items={item?.eventCandidates}
                                columns={eventCandidatesColumn}
                                status={status}
                                noDataText="Aucun évènements n'a été trouvé"
                            />
                        </Card>
                    </PivotItem>
                </Pivot>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdCandidates.prototype.isValidDate = isValidDate
