/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Mission from 'requests/objects/mission'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import BoardComment from 'requests/objects/boardComment'
import EventClient from 'requests/objects/eventClient'

/**
 * Home Object
 */
export default class Home extends Base {
    /**
     * @param {object} data data
     * @param {object[]=} data.runningMissions runningMissions
     * @param {object[]=} data.closedMissions closedMissions
     * @param {object[]=} data.dispatchMissions dispatchMissions
     * @param {object[]=} data.runningWithNewCandidacyMissions runningWithNewCandidacyMissions
     * @param {number=} data.currentWallet currentWallet
     * @param {number=} data.forecast forecast
     * @param {number=} data.monthlyTurnover monthlyTurnover
     * @param {number=} data.annualTurnover annualTurnover
     * @param {number=} data.totalTurnover totalTurnover
     * @param {number=} data.clientVisitsTotal clientVisitsTotal
     * @param {object=} data.roadmaps roadmaps
     * @param {object[]=} data.expiredCandidacies expiredCandidacies
     * @param {object[]=} data.sendingCvCandidacies sendingCvCandidacies
     * @param {object[]=} data.interviewCandidacies interviewCandidacies
     */
    constructor({
        runningMissions = [],
        closedMissions = [],
        dispatchMissions = [],
        runningWithNewCandidacyMissions = [],
        currentWallet = null,
        forecast = null,
        monthlyTurnover = null,
        annualTurnover = null,
        totalTurnover = null,
        clientVisitsTotal = null,
        roadmaps = {},
        expiredCandidacies = [],
        sendingCvCandidacies = [],
        interviewCandidacies = [],
        ...data
    } = {}) {
        super(data)
        this.runningMissions = runningMissions?.map(x => new Mission(x)) ?? []
        this.closedMissions = closedMissions?.map(x => new Mission(x)) ?? []
        this.dispatchMissions = dispatchMissions?.map(x => new Mission(x)) ?? []
        this.runningWithNewCandidacyMissions = runningWithNewCandidacyMissions?.map(x => new Mission(x)) ?? []
        this.currentWallet = currentWallet ?? null
        this.forecast = forecast ?? null
        this.monthlyTurnover = monthlyTurnover ?? null
        this.annualTurnover = annualTurnover ?? null
        this.totalTurnover = totalTurnover ?? null
        this.clientVisitsTotal = clientVisitsTotal ?? null
        this.roadmaps = {
            /** @type {BoardComment[]} */
            pinnedBoardComments: roadmaps?.pinnedBoardComments?.map(x => new BoardComment(x)) ?? [],
            /** @type {Mission[]} */
            briefs: roadmaps?.briefs?.map(x => new Mission(x)) ?? [],
            /** @type {(Candidacy|Hunt)[]} */
            interviews: roadmaps?.interviews?.map(x => {
                if (x.candidacyId)
                    return new Candidacy(x)
                if (x.huntId)
                    return new Hunt(x)
                return x
            }) ?? [],
            /** @type {(Candidacy|Hunt)[]} */
            proposals: roadmaps?.proposals?.map(x => {
                if (x.candidacyId)
                    return new Candidacy(x)
                if (x.huntId)
                    return new Hunt(x)
                return x
            }) ?? [],
            /** @type {EventClient[]} */
            clientVisits: roadmaps?.clientVisits?.map(x => new EventClient(x)) ?? [],
            /** @type {EventClient[]} */
            todos: roadmaps?.todos?.map(x => new EventClient(x)) ?? [],
            toCalls: [], // This was never set
            /** @type {Candidacy[]} */
            returnAnnouncementCandidacies: roadmaps?.returnAnnouncementCandidacies?.map(x => new Candidacy(x)) ?? [],
        }

        /** @type {(Candidacy | Hunt)[]} */
        this.expiredCandidacies = expiredCandidacies?.map(x => {
            if (x.candidacyId)
                return new Candidacy(x)
            if (x.huntId)
                return new Hunt(x)
            return x
        }) ?? []
        /** @type {(Candidacy | Hunt)[]} */
        this.sendingCvCandidacies = sendingCvCandidacies?.map(x => {
            if (x.candidacyId)
                return new Candidacy(x)
            if (x.huntId)
                return new Hunt(x)
            return x
        }) ?? []
        /** @type {(Candidacy | Hunt)[]} */
        this.interviewCandidacies = interviewCandidacies?.map(x => {
            if (x.candidacyId)
                return new Candidacy(x)
            if (x.huntId)
                return new Hunt(x)
            return x
        }) ?? []
    }
}

/**
 * Home Object used to bind error message
 */
export class ErrorHome extends ErrorBase { }
