import Mission, { ErrorMission } from 'requests/objects/mission'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import File from 'requests/objects/file'
import EDiffusionAction from 'types/missions/enums/diffusionAction'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from '@fluentui/react'

/**
 * MissionsHandler
 * @augments {ApiHandler<Mission, ErrorMission>}
 */
export default class MissionsHandler extends ApiHandler {
    constructor() {
        super({ type: Mission, errorType: ErrorMission, key: 'missions' })
    }

    /**
     * Get file
     * @param {number} missionId Id
     * @param {string} key Key
     * @returns {RequestApi<Blob>} Request
     */
    getFile(missionId, key) {
        const request = this.initFetchRequest({
            url: [missionId, 'files', key], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} missionId Id
     * @param {string} key Key
     * @param {globalThis.File} file file
     * @returns {RequestApi<File>} Returns
     */
    uploadFile(missionId, key, file) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [missionId, 'files', key],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new File(/** @type {any} */(res.data)?.files))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} missionId Id
     * @param {string} key Key
     * @returns {RequestApi<File>} Request
     */
    removeFile(missionId, key) {
        const request = this.initFetchRequest({
            url: [missionId, 'files', key], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get all
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {RequestApi<Mission[]>} Request
     */
    getAllMap(params = {}) {
        const request = this.initFetchRequest({ url: ['map'], params })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * diffuse
     * @param {Mission=} mission mission
     * @param {EDiffusionAction=} actionName actionName
     * @returns {RequestApi<Mission>} Request
     */
    diffuse(mission, actionName) {
        const request = this.initFetchRequest({
            url: [mission.missionId, 'diffuse'],
            method: 'POST',
            data: {
                actionName,
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new Mission(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Complete diffusion
     * @param {number=} missionId missionId
     * @returns {RequestApi<Mission>} Request
     */
    completeDiffusion(missionId) {
        const request = this.initFetchRequest({ url: [missionId, 'diffuse/complete-diffusion'] })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new Mission(res.data.mission))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Refresh diffusion data
     * @param {number=} missionId missionId
     * @returns {RequestApi<Mission>} Request
     */
    refreshDiffusion(missionId) {
        const request = this.initFetchRequest({ url: [missionId, 'diffuse/refresh'] })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new Mission(res.data.mission))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Cancel diffusion
     * @param {number=} missionId missionId
     * @returns {RequestApi<Mission>} Request
     */
    cancelDiffusion(missionId) {
        const request = this.initFetchRequest({ url: [missionId, 'diffuse/cancel-diffusion'] })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new Mission(res.data.mission))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Backout
     * @param {number} missionId missionId
     * @returns {RequestApi<Mission>} Request
     */
    backout(missionId) {
        const request = this.initFetchRequest({ url: [missionId, 'backout'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * diffuse
     * @param {Mission} mission mission
     * @param {number} missionId missionId
     * @returns {RequestApi<Mission>} Request
     */
    updateWebsiteInfo(mission, missionId) {
        const request = this.initFetchRequest({ url: [missionId, 'website', 'informations'], method: 'PATCH', data: mission })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été mis à jour" }))
                    // eslint-disable-next-line new-cap
                    return new (this.type)(res.data[this.objectName])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * diffuse
     * @param {number} missionId missionId
     * @returns {RequestApi<Mission>} Request
     */
    diffuseWebsiteInfo(missionId) {
        const request = this.initFetchRequest({ url: [missionId, 'website', 'diffuse'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'La mission a bien été diffusé' }))
                    // eslint-disable-next-line new-cap
                    return new (this.type)(res.data[this.objectName])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get file
     * @param {number} missionId Id
     * @param {number} boardColumnId boardColumnId
     * @returns {RequestApi<Blob>} Request
     */
    getExportedItems(missionId, boardColumnId) {
        const request = this.initFetchRequest({
            url: [missionId, 'export', boardColumnId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
