import React, { PureComponent } from 'react'
import {
    // eslint-disable-next-line max-len
    MessageBarType, TextField, ICommandBarItemProps, Label, Toggle, PivotItem, Pivot, DefaultButton, Text,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import Contact, { ErrorContact } from 'requests/objects/contact'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import Time from 'helpers/methods/time'
import { Link } from 'react-router-dom'
import setPageTitle from 'helpers/methods/pageTitle'
import EventFileInput from 'components/pages/clients/[id]/index/contacts/[id]/evenements/eventFileInput'
import SortableList from 'components/pages/clients/[id]/index/sortableList'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdContacts extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.contactId,
            /** @type {Contact} Element find from API */
            item: new Contact(),
            /** @type {Contact} Initial element fond from API. Used to refresh */
            iniItem: new Contact(),
            /** @type {ErrorContact} Errors */
            errorField: new ErrorContact(),
            /** @type {string} Current pivot to display */
            pivotSelectedKey: 'Données',
            eventClientsColumn: [
                {
                    key: 'hasTodo',
                    name: 'A TODO',
                    fieldName: 'hasTodo',
                    minWidth: 75,
                    maxWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    onRender: item => (item.hasTodo ? 'Oui' : 'Non'),
                },
                {
                    key: 'comment',
                    name: 'Commentaire',
                    fieldName: 'comment',
                    minWidth: 150,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text
                            title={item?.comment}
                            block
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}
                        >
                            {item?.comment}
                        </Text>
                    ),
                },
                {
                    key: 'libelle',
                    name: 'Libellé',
                    fieldName: 'eventType.name',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {item['eventType.name'] || ''}
                        </Text>
                    ),
                },
                {
                    key: 'missionId',
                    name: 'Mission',
                    fieldName: 'mission.ref',
                    minWidth: 100,
                    maxWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item['mission.missionId'] ? (
                        <Link
                            to={{
                                pathname: `/missions/${item['mission.missionId']}`,
                            }}
                        >
                            {item['mission.ref'] || ''}
                        </Link>
                    ) : ''),
                },
                {
                    key: 'updatedAt',
                    name: 'Mise à jour',
                    fieldName: 'updatedAt',
                    minWidth: 150,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item?.updatedAt ? Time(new Date(item.updatedAt)).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                },
                {
                    key: 'todoDate',
                    name: 'Date TODO',
                    fieldName: 'todoDate',
                    minWidth: 150,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item?.todoDate ? Time(new Date(item.todoDate.toString())).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                },
                {
                    key: 'userCollaborator',
                    name: 'Collaborateur',
                    fieldName: 'userCollaborator.lastname',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    onRender: item => [item['userCollaborator.lastname'], item['userCollaborator.firstname']].filter(x => x).join(' ').trim() || 'Inconnu',
                },
                {
                    key: 'action',
                    name: '',
                    minWidth: 150,
                    maxWidth: 150,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item.eventTypeId === 35 // ENVOI CONTRAT COLLABORATION
                        ? (
                            <EventFileInput
                                label=""
                                isReadOnly
                                contactId={item.contactId}
                                eventClientId={item.eventClientId}
                                // eslint-disable-next-line react/destructuring-assignment
                                handler={this.props.contactsHandler}
                                updateItem={() => null}
                                item={item}
                            />
                        )
                        : null),
                },
            ],
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous state
     */
    componentDidUpdate(prevProps, prevState) {
        const { match, setCommand } = this.props
        const { pivotSelectedKey, item } = this.state

        if (match?.params?.contactId !== prevProps?.match?.params?.contactId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (prevState.item?.divisionId !== item?.divisionId && item?.divisionId && prevState.item?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                item: {
                    ...truePrevState.item,
                    subDivisionId: null,
                    metierId: null,
                },
            }))

        if (prevState.item?.subDivisionId !== item?.subDivisionId && item?.subDivisionId && prevState.item?.subDivisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                item: {
                    ...truePrevState.item,
                    metierId: null,
                },
            }))

        if (prevState.pivotSelectedKey !== pivotSelectedKey) {
            this.setupCommandBar()
            setCommand(this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.contactsHandlerRemoveById?.cancel()
        this.contactsHandlerGetById?.cancel()
        this.contactsHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Contact ${match?.params?.contactId ? [item.lastname, item.firstname].filter(x => x).join(' ').trim() || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie client', key: 'client' },
            { text: 'Tous les clients', key: 'all-client', href: '/clients' },
            {
                text: `Client ${match?.params?.clientId ? item.client?.name || '' : 'Nouveau'}`,
                key: 'one-client',
                href: `/clients/${match?.params?.clientId}`,
            },
            {
                text: mainTitle,
                key: 'one-parent-company',
                isCurrentItem: true,
            },
        ])
        setTitle('Clients')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, contactsHandler, setMessageBar,
        } = this.props
        const { pivotSelectedKey } = this.state
        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
        ].filter(x => x.isVisible)

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorContact(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.contactId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer le contact',
                        subTitle: 'Êtes-vous certain de vouloir supprimer le contact ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.contactsHandlerRemoveById = contactsHandler.removeById(match?.params?.contactId)
                                    await this.contactsHandlerRemoveById.fetch()
                                    history.push(`/clients/${match?.params?.clientId}`)
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.contactId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, contactsHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.contactId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.contact) // If object came from history push with a create
                    this.setState({
                        item: location.state?.contact,
                        iniItem: location.state?.contact,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.contactsHandlerGetById = contactsHandler.getById(match?.params?.contactId)
                        const contact = await this.contactsHandlerGetById.fetch()
                        this.setState({
                            item: contact,
                            iniItem: contact,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Contact({
                        clientId: Number.parseInt(match.params?.clientId, 10),
                        ...(location.state?.contact ?? {}),
                    }),
                    iniItem: new Contact({
                        clientId: Number.parseInt(match.params?.clientId, 10),
                        ...(location.state?.contact ?? {}),
                    }),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, contactsHandler, match, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.contactsHandlerUpsert = contactsHandler.upsert(item, match?.params?.contactId)
                const contact = await this.contactsHandlerUpsert.fetch()

                this.setState({
                    item: contact,
                    iniItem: contact,
                    status: Status.RESOLVED,
                    errorField: new ErrorContact(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.contactId)
                    history.replace(`/clients/${match?.params?.clientId}/contacts/${contact.contactId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorContact>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, eventClientsColumn, pivotSelectedKey,
        } = this.state
        const { param } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Pivot
                    styles={{ itemContainer: { paddingTop: '20px' } }}
                    selectedKey={pivotSelectedKey}
                    onLinkClick={pivotItem => this.setState({ pivotSelectedKey: pivotItem.props.itemKey })}
                >
                    <PivotItem
                        headerText="Données"
                        itemIcon="PageData"
                        itemKey="Données"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <Card
                            title="Champs"
                            iconName="PageData"
                        >
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Nom"
                                        value={item.lastname}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, lastname: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.lastname}
                                        required
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Prénom"
                                        value={item.firstname}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, firstname: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.firstname}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Email"
                                        value={item.email}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, email: newVal } })}
                                        errorMessage={errorField.email}
                                        type="email"
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <Label
                                        htmlFor="hasLeft"
                                    >
                                        Out
                                    </Label>
                                    <Toggle
                                        id="hasLeft"
                                        onText="Oui"
                                        offText="Non"
                                        checked={item.hasLeft}
                                        onChange={(_ev, checked) => this.setState({ item: { ...item, hasLeft: checked } })}
                                        disabled={isReadOnly}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Téléphone mobile"
                                        value={item.mobilePhone}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, mobilePhone: newVal } })}
                                        errorMessage={errorField.mobilePhone}
                                        type="phone"
                                        required={!item.landlinePhone}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Téléphone fixe"
                                        value={item.landlinePhone}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, landlinePhone: newVal } })}
                                        errorMessage={errorField.landlinePhone}
                                        type="phone"
                                        required={!item.mobilePhone}
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Adresse"
                            iconName="CityNext"
                        >
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Adresse"
                                        value={item.address}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, address: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.address}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Complément"
                                        value={item.additionalAddress}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, additionalAddress: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.additionalAddress}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Code postal"
                                        value={item.zipCode}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, zipCode: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.zipCode}
                                        required
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Ville"
                                        readOnly={isReadOnly}
                                        options={param.cities}
                                        selectedKey={item.cityId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, cityId: option.key } })}
                                        errorMessage={errorField.cityId}
                                        required
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Compléments"
                            iconName="Relationship"
                        >
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Famille de poste"
                                        readOnly={isReadOnly}
                                        options={param.divisions}
                                        selectedKey={item.divisionId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, divisionId: option.key } })}
                                        errorMessage={errorField.divisionId}
                                        required
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Sous-famille"
                                        readOnly={isReadOnly}
                                        options={param.subDivisions.filter(x => !item.divisionId || x.divisionId === item.divisionId)}
                                        selectedKey={item.subDivisionId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, subDivisionId: option.key } })}
                                        errorMessage={errorField.subDivisionId}
                                        required
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Metier"
                                        readOnly={isReadOnly}
                                        options={param.metiers.filter(x => !item.subDivisionId || item.subDivisionId === x.subDivisionId)}
                                        selectedKey={item.metierId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, metierId: option.key } })}
                                        errorMessage={errorField.metierId}
                                        required
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Autre"
                            iconName="WebAppBuilderFragment"
                        >
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Informations"
                                        value={item.information}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, information: newVal?.toUpperCase() } })}
                                        errorMessage={errorField.information}
                                        multiline
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                    </PivotItem>
                    <PivotItem
                        headerText="Évènements"
                        itemIcon="Event"
                        itemKey="Événements"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <Card
                            title="Évènements"
                            iconName="Event"
                        >
                            <DefaultButton
                                text="Nouvel évènement"
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => history.push(`/clients/${item?.clientId}/contacts/${item?.contactId}/evenements/${NEW_PATH}`)}
                                disabled={!isReadOnly || !item.contactId}
                            />
                            <br />
                            <br />
                            {item?.eventClients?.length > 0
                                && (
                                    <SortableList
                                        items={item?.eventClients}
                                        columns={eventClientsColumn}
                                        status={status}
                                        noDataText="Aucun évènements n'a été trouvé"
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        onRenderRow={(props, defaultRender) => (
                                            <Link
                                                to={`/clients/${item?.clientId}/contacts/${item?.contactId}/evenements/${props.item?.eventClientId}`}
                                                onClick={ev => {
                                                    // eslint-disable-next-line prefer-destructuring
                                                    const target = /** @type {HTMLElement} */(ev.target)
                                                    if (target.closest('button') || target.nodeName.toLowerCase() === 'button')
                                                        ev.preventDefault()
                                                }}
                                            >
                                                {defaultRender({
                                                    ...props,
                                                    styles: {
                                                        cell: { display: 'flex', alignSelf: 'center' },
                                                        checkCell: { display: 'flex', alignSelf: 'center', height: '100%' },
                                                    },
                                                })}
                                            </Link>
                                        )}
                                    />
                                )}
                        </Card>
                    </PivotItem>
                </Pivot>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdContacts.prototype.getUpdatedList = getUpdatedList
IdContacts.prototype.isValidDate = isValidDate
