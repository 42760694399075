/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Sector from 'requests/objects/sector'
import Contact from 'requests/objects/contact'
import ParentCompany from 'requests/objects/parentCompany'
import Mission from 'requests/objects/mission'

/**
 * Client Object
 */
export default class Client extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.clientId clientId of the Client
     * @param {string=} data.name name
     * @param {string=} data.address address
     * @param {string=} data.zipCode zipCode
     * @param {string=} data.siret siret
     * @param {number=} data.cityId city
     * @param {object=} data.city city
     * @param {string=} data.landlinePhone landlinePhone
     * @param {string=} data.activity activity
     * @param {string=} data.information information
     * @param {string=} data.website website
     * @param {number=} data.departmentId departmentId
     * @param {object=} data.department department
     * @param {number=} data.natureId natureId
     * @param {object=} data.nature nature
     * @param {string=} data.workforceId workforceId
     * @param {number=} data.turnoverId turnoverId
     * @param {object=} data.turnover turnover
     * @param {number=} data.sectorId sector1Id
     * @param {object=} data.sector sector1
     * @param {boolean=} data.isVisited isVisited
     * @param {boolean=} data.hasOpenMission hasOpenMission
     * @param {string=} data.lastestMissionDate lastestMissionDate
     * @param {number=} data.parentCompanyId parentCompanyId
     * @param {object=} data.parentCompany parentCompany
     * @param {number=} data.latestContactId latestContactId
     * @param {object=} data.latestContact latestContact
     * @param {boolean=} data.isParent isParent
     * @param {object[]=} data.contacts contacts
     * @param {object[]=} data.contactEvents contactEvents
     * @param {string=} data.latitude latitude
     * @param {string=} data.longitude longitude
     * @param {object[]=} data.missions missions
     */
    constructor({
        clientId = 0,
        name = '',
        address = '',
        zipCode = '',
        siret = '',
        cityId,
        city = {},
        landlinePhone = '',
        activity = '',
        information = '',
        website = '',
        departmentId,
        department,
        natureId = 2,
        nature,
        workforceId,
        turnoverId,
        turnover,
        sectorId,
        sector,
        isVisited,
        hasOpenMission,
        lastestMissionDate = '',
        parentCompanyId,
        parentCompany,
        latestContactId,
        latestContact,
        isParent,
        contacts = [],
        contactEvents,
        latitude,
        longitude,
        missions = [],
        ...data
    } = {}) {
        super(data)
        this.clientId = clientId
        this.name = name
        this.address = address
        this.zipCode = zipCode
        this.siret = siret
        this.cityId = cityId
        this.city = city
        this.landlinePhone = landlinePhone
        this.activity = activity
        this.information = information
        this.website = website
        this.departmentId = departmentId
        this.department = department
        this.natureId = natureId
        this.nature = nature
        this.workforceId = workforceId
        this.turnoverId = turnoverId
        this.turnover = turnover
        this.sectorId = sectorId
        this.sector = sector ? new Sector(sector) : undefined
        this.isVisited = isVisited
        this.hasOpenMission = hasOpenMission
        this.lastestMissionDate = lastestMissionDate
        this.parentCompanyId = parentCompanyId
        this.parentCompany = parentCompany ? new ParentCompany(parentCompany) : undefined
        this.latestContactId = latestContactId
        this.latestContact = latestContact
        this.isParent = isParent

        this.contacts = contacts?.map(x => new Contact(x)) ?? []
        this.contactEvents = this.contacts.map(contact => contact.eventClients).flat()
            .sort((a, b) => new Date(b.updatedAt || 0).getTime() - new Date(a.updatedAt || 0).getTime())

        this.latitude = !Number.isNaN(Number.parseFloat(latitude)) ? Number.parseFloat(latitude) : 0
        this.longitude = !Number.isNaN(Number.parseFloat(longitude)) ? Number.parseFloat(longitude) : 0

        this.missions = missions?.map(x => new Mission(x)) ?? []
    }
}

/**
 * Client Object used to bind error message
 */
export class ErrorClient extends ErrorBase {
    constructor() {
        super()
        this.clientId = ''
        this.name = ''
        this.address = ''
        this.zipCode = ''
        this.siret = ''
        this.cityId = ''
        this.city = ''
        this.landlinePhone = ''
        this.activity = ''
        this.information = ''
        this.website = ''
        this.departmentId = ''
        this.natureId = ''
        this.workforceId = ''
        this.turnoverId = ''
        this.sectorId = ''
        this.isVisited = ''
        this.hasOpenMission = ''
        this.lastestMissionDate = ''
        this.parentCompanyId = ''
        this.latestContactId = ''
        this.isParent = ''
    }
}
