import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import {
    Dialog, DialogFooter, DialogType, PrimaryButton, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import Candidacy, { ErrorCandidacy } from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Hunt, { ErrorHunt } from 'requests/objects/hunt'
import Time from 'helpers/methods/time'
import EBoardCardType from 'types/missions/enums/boardCardType'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { isValidDate } from 'helpers/methods/common'
import Param from 'requests/objects/param'

/**
 * BoardSetInterviewDateModal
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @param {Param} props.param param
 * @returns {JSX.Element} Returns
 */
export default function BoardSetInterviewInfoModal({
    item: itemProps, handler, isVisible, setIsVisible, onUpdate, param,
}) {
    const [item, setItem] = useState(itemProps || new Candidacy())
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(/** @type {ErrorHunt | ErrorCandidacy} */({}))

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerUpdateBoardItem = useRef(null)

    /** @type {React.MutableRefObject<HTMLButtonElement>} */
    const submitInputRef = useRef(null)

    const isMerging = useRef(false)

    const isUncompletHunt = useMemo(
        () => itemProps && itemProps.type === EBoardCardType.HUNT
            // eslint-disable-next-line max-len
            && (!itemProps.candidate?.firstname || !itemProps.candidate?.lastname || !itemProps.candidate?.email || !itemProps.candidate?.birthdate || !itemProps.candidate?.civilityId),
        [itemProps],
    )

    const update = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerUpdateBoardItem.current = handler.patchInterviewInfo(
                /** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId,
                item,
                { isMerging: isMerging.current },
            )
            const newItem = await handlerUpdateBoardItem.current.fetch()
            setStatus(Status.RESOLVED)
            setIsVisible(false)
            onUpdate(newItem)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorHunt | ErrorCandidacy>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [item, handler, setIsVisible, onUpdate])

    // Update local item on props change
    useEffect(() => {
        setItem(itemProps || new Candidacy())
        setErrorField(/** @type {ErrorHunt | ErrorCandidacy} */({}))
        isMerging.current = false
    }, [itemProps])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateBoardItem?.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: `Planifier l'entretien${isUncompletHunt ? ' et compléter la candidature' : ''}`,
                // eslint-disable-next-line max-len
                subText: `Veuillez sélectionner les informations nécessaires pour planifier l'entretien ${isUncompletHunt ? ' et compléter la candidature' : ''}`,
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    update()
                }}
            >
                {isUncompletHunt && (
                    <>
                        <TextField
                            label="Nom"
                            value={/** @type {Hunt} */(item).candidate?.lastname}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({
                                ...prevValue,
                                candidate: { ...prevValue.candidate, lastname: newVal?.toUpperCase() },
                            }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.lastname']}
                            required
                        />
                        <TextField
                            label="Prénom"
                            value={/** @type {Hunt} */(item).candidate?.firstname}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({
                                ...prevValue,
                                candidate: { ...prevValue.candidate, firstname: newVal?.toUpperCase() },
                            }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.firstname']}
                            required
                        />
                        <TextField
                            label="Email"
                            value={/** @type {Candidacy} */(item).candidate?.email}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, email: newVal } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.email']
                                // eslint-disable-next-line react/no-danger
                                ? <span dangerouslySetInnerHTML={{ __html: /** @type {ErrorHunt} */(errorField)['candidate.email'] }} />
                                : null}
                            required
                            type="email"
                        />
                        <FilteredVirtualCombobox
                            label="Civilité"
                            readOnly={status === Status.PENDING}
                            options={param.civilities}
                            selectedKey={/** @type {Hunt} */(item).candidate?.civilityId}
                            onChange={(_ev, option) => setItem(prevValue => ({ ...prevValue, candidate: { ...prevValue.candidate, civilityId: option.key } }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.civilityId']}
                            required
                        />
                        <TextField
                            label={`Date de naissance${item?.candidate?.birthdate ? ` (${Time(item?.candidate?.birthdate).getAge()} ans)` : ''}`}
                            value={item?.candidate?.birthdate ? Time(item?.candidate?.birthdate).getLocaleDateString() : ''}
                            readOnly={status === Status.PENDING}
                            borderless={status === Status.PENDING}
                            onChange={(_ev, newVal) => setItem(prevValue => ({
                                ...prevValue,
                                candidate: { ...prevValue.candidate, birthdate: isValidDate(new Date(newVal)) ? new Date(newVal) : /** @type {any} */('') },
                            }))}
                            errorMessage={/** @type {ErrorHunt} */(errorField)['candidate.civilityId']}
                            type="date"
                            required
                        />
                        <hr />
                    </>
                )}
                <TextField
                    label="Date et heure du début"
                    value={item.interviewStartDate && isValidDate(item.interviewStartDate) ? Time(item.interviewStartDate).getLocaleDateTimeString() : ''}
                    readOnly={status === Status.PENDING}
                    borderless={status === Status.PENDING}
                    onChange={(_ev, newVal) => {
                        const newInterviewStartDate = new Date(newVal)
                        setItem(prev => ({
                            ...prev,
                            interviewStartDate: new Date(newVal),
                            // eslint-disable-next-line max-len
                            interviewEndDate: new Date(`${newVal.slice(0, 10)}T${(newInterviewStartDate.getHours() + 1).toString().padStart(2, '0')}:${newInterviewStartDate.getMinutes().toString().padStart(2, '0')}`),
                        }))
                    }}
                    errorMessage={errorField.interviewStartDate}
                    type="datetime-local"
                    required
                />
                {!isUncompletHunt && (<br />)}
                <TextField
                    label="Heure de fin"
                    value={item.interviewEndDate && isValidDate(item.interviewEndDate) ? Time(item.interviewEndDate).getLocaleTimeString() : ''}
                    readOnly={status === Status.PENDING}
                    borderless={status === Status.PENDING}
                    onChange={(_ev, newVal) => {
                        setItem(prev => ({
                            ...prev,
                            interviewEndDate: new Date(`${Time(prev.interviewStartDate).getLocaleDateString()}T${newVal}`),
                        }))
                    }}
                    errorMessage={errorField.interviewEndDate}
                    type="time"
                    required
                    disabled={!item.interviewStartDate}
                />
                {!isUncompletHunt && (<br />)}
                <DialogFooter>
                    <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-start' }}>
                        <PrimaryButton
                            type="submit"
                            text="Enregistrer"
                            disabled={status === Status.PENDING}
                        />
                        <TooltipHost
                            // eslint-disable-next-line max-len
                            content="Si l'email existe déjà, cliquez ici pour déplacer la candidature sur le candidat existant, mettre à jour les données du candidat et supprimer le nouveau candidat. Cette action est définitive."
                            delay={TooltipDelay.zero}
                        >
                            <PrimaryButton
                                onClick={() => {
                                    isMerging.current = true
                                    submitInputRef.current.click()
                                }}
                                disabled={status === Status.PENDING
                                    || !(/** @type {ErrorCandidacy} */(errorField))['candidate.email']?.includes('La valeur existe déja')}
                            >
                                Fusionner
                            </PrimaryButton>
                        </TooltipHost>
                    </div>
                </DialogFooter>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={submitInputRef}
                    tabIndex={-1}
                />
            </form>
        </Dialog>
    )
}
