import React, { PureComponent } from 'react'
import {
    // @ts-ignore
    MessageBarType, ICommandBarItemProps, Pivot, PivotItem, Text, Toggle,
} from '@fluentui/react'
import history from 'helpers/history'
// @ts-ignore
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { isValidDate, onColumnClick } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { NEW_PATH } from 'types/others'
import Mission, { ErrorMission } from 'requests/objects/mission'
import PivotDetail from 'components/pages/missions/[id]/pivotDetail'
import PivotCandidacy from 'components/pages/missions/[id]/pivotCandidacy'
import PivotDiffusion from 'components/pages/missions/[id]/pivotDiffusion'
import EProfile from 'types/profile'
import PivotHistoric from 'components/pages/missions/[id]/pivotHistoric'
import PivotEvent from 'components/pages/missions/[id]/pivotEvent'
import Time from 'helpers/methods/time'
import { Link } from 'react-router-dom'
import PivotDocuments from 'components/pages/missions/[id]/pivotDocuments'
import setPageTitle from 'helpers/methods/pageTitle'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdMissions extends PureComponent {
    constructor(props) {
        super(props)
        const { match, location } = this.props

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.missionId,
            /** @type {Mission} Element find from API */
            item: new Mission(),
            /** @type {Mission} Initial element fond from API. Used to refresh */
            iniItem: new Mission(),
            /** @type {ErrorMission} Errors */
            errorField: new ErrorMission(),
            /** @type {string} Current pivot to display */
            pivotSelectedKey: location.state?.pivotSelectedKey || (match?.params?.missionId ? 'Candidatures' : 'Données'),
            /** @type {boolean} Is sending mail mode enable on Candidature pivot */
            isSendMailEnable: false,
            /** @type {boolean} Is sending mail modal visible on Candidature pivot */
            isSendMailModalVisible: false,
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            eventClientsColumns: [
                {
                    key: 'eventType',
                    name: 'Type',
                    fieldName: 'eventType.name',
                    minWidth: 250,
                    maxWidth: 250,
                    isRowHeader: true,
                    isResizable: true,
                    onRender: item => item['eventType.name'],
                },
                {
                    key: 'hasTodo',
                    name: 'A TODO',
                    fieldName: 'hasTodo',
                    minWidth: 100,
                    maxWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    onRender: item => (item.hasTodo ? 'Oui' : 'Non'),
                },
                {
                    key: 'comment',
                    name: 'Commentaire',
                    fieldName: 'comment',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text
                            title={item?.comment}
                            block
                            style={{
                                whiteSpace: 'pre-wrap',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                            }}
                        >
                            {item?.comment}
                        </Text>
                    ),
                },
                {
                    key: 'clientId',
                    name: 'Contact',
                    fieldName: 'contact.lastname',
                    minWidth: 100,
                    maxWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Link
                            to={{
                                pathname: `/clients/${item['contact.clientId']}/contacts/${item?.contactId}`,
                            }}
                        >
                            {[item['contact.lastname'], item['contact.firstname']].filter(x => x).join(' ').trim() || 'Inconnu'}
                        </Link>
                    ),
                },
                {
                    key: 'updatedAt',
                    name: 'Mise à jour',
                    fieldName: 'updatedAt',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item?.updatedAt ? Time(new Date(item.updatedAt)).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                },
                {
                    key: 'todoDate',
                    name: 'Date TODO',
                    fieldName: 'todoDate',
                    minWidth: 150,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item?.todoDate ? Time(new Date(item.todoDate.toString())).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                },
            ],
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            eventCandidatesColumn: [
                {
                    key: 'eventType.name',
                    name: 'Type',
                    fieldName: 'eventType.name',
                    minWidth: 250,
                    maxWidth: 250,
                    isRowHeader: true,
                    isResizable: true,
                    onColumnClick: (ev, column) => this.onColumnClick({
                        colName: 'eventCandidatesColumn', dataName: ['item', 'eventCandidates'], source: 'state', action: () => null, isFlatten: true,
                    }, ev, column),
                },
                {
                    key: 'candidate.lastname',
                    name: 'Candidature',
                    fieldName: 'candidate.lastname',
                    minWidth: 150,
                    maxWidth: 250,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (item?.candidacyId
                        ? (
                            <Link
                                to={{
                                    pathname: `/candidats/${item?.candidateId}/candidatures/${item?.candidacyId}`,
                                }}
                            >
                                {[item['candidate.lastname'], item['candidate.firstname']].filter(x => x).join(' ').trim() || 'Inconnu'}
                            </Link>
                        )
                        : [item['candidate.lastname'], item['candidate.firstname']].filter(x => x).join(' ').trim() || 'Inconnu'),
                    onColumnClick: (ev, column) => this.onColumnClick({
                        colName: 'eventCandidatesColumn', dataName: ['item', 'eventCandidates'], source: 'state', action: () => null, isFlatten: true,
                    }, ev, column),
                },
                {
                    key: 'updatedAt',
                    name: 'Mise à jour',
                    fieldName: 'updatedAt',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: item => (
                        <Text>
                            {(item.updatedAt ? Time(new Date(item.updatedAt)).getCleanDate({
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            }) : '')}
                        </Text>
                    ),
                    onColumnClick: (ev, column) => this.onColumnClick({
                        colName: 'eventCandidatesColumn', dataName: ['item', 'eventCandidates'], source: 'state', action: () => null, isFlatten: true,
                    }, ev, column),
                },
            ],
            /** Is hidding rejected card in board */
            isHiddingReject: false,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous state
     */
    componentDidUpdate(prevProps, prevState) {
        const { match, setCommand } = this.props
        const { pivotSelectedKey, item } = this.state

        if (match?.params?.missionId !== prevProps?.match?.params?.missionId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (prevState.pivotSelectedKey !== pivotSelectedKey) {
            this.setupCommandBar()
            setCommand(this.commandRead)
        }

        if (prevState.item?.divisionId !== item?.divisionId && item?.divisionId && prevState.item?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                item: {
                    ...truePrevState.item,
                    subDivisionId: null,
                    metierId: null,
                },
            }))

        if (prevState.item?.subDivisionId !== item?.subDivisionId && item?.subDivisionId && prevState.item?.subDivisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                item: {
                    ...truePrevState.item,
                    metierId: null,
                },
            }))
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.missionsHandlerRemoveById?.cancel()
        this.missionsHandlerGetById?.cancel()
        this.missionsHandlerUpsert?.cancel()
        this.missionsHandlerBackout?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state

        const mainTitle = `Mission ${match?.params?.missionId ? `${item.ref || ''}` : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie mission', key: 'mission' },
            { text: 'Toutes les missions', key: 'all-missions', href: '/missions' },
            { text: mainTitle, key: 'one-mission', isCurrentItem: true },
        ])
        setTitle('Missions')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, missionsHandler, setMessageBar, me,
        } = this.props
        const {
            pivotSelectedKey, isSendMailEnable, item, isHiddingReject,
        } = this.state

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                isVisible: ['Données', 'Documents', 'Diffusions'].includes(pivotSelectedKey),
            },
            {
                key: 'isSendMailEnable',
                text: 'Email',
                iconProps: { iconName: 'NewMail' },
                onClick: () => this.setState({ isSendMailEnable: true, isReadOnly: false }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                }),
                isVisible: ['Candidatures'].includes(pivotSelectedKey) && !isSendMailEnable,
            },
            {
                key: 'backout',
                text: 'Backout',
                iconProps: { iconName: 'SkypeCircleArrow' },

                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Backout',
                        subTitle: 'Voulez-vous faire le backout de la mission ? Toutes les candidatures seront réactivés. Cette action est définitive.',
                        callback: () => {
                            // eslint-disable-next-line consistent-return
                            this.setState({ status: Status.PENDING }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.missionsHandlerBackout = missionsHandler.backout(match?.params?.missionId)
                                    const newMission = await this.missionsHandlerBackout.fetch()
                                    history.push(`/missions/${newMission.missionId}`)
                                    this.setupCommandBar()
                                    setCommand(this.commandRead)
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            this.setupCommandBar()
                                            setCommand(this.commandRead)
                                            this.setState({ status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                isVisible: ['Données'].includes(pivotSelectedKey),
                disabled: item?.missionStatusId !== 2 || ![EProfile.Administrator, EProfile.Consultant].includes(me.profileId),
            },
            {
                key: 'isHiddingReject',
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: () => (
                    <Toggle
                        label="Cacher rejetées"
                        checked={isHiddingReject}
                        onChange={(ev, checked) => this.setState({ isHiddingReject: checked }, () => {
                            this.setupCommandBar()
                            setCommand(this.commandRead)
                        })}
                        styles={{
                            root: {
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'row-reverse',
                                margin: '0',
                            },
                            label: {
                                fontWeight: 'bold',
                            },
                            container: {
                                alignSelf: 'center',
                                margin: '0 0.25rem',
                            },
                        }}
                    />
                ),
                isVisible: ['Candidatures'].includes(pivotSelectedKey),
            },
        ].filter(x => x.isVisible)

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorMission(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.missionId,
                isVisible: ['Données', 'Documents', 'Diffusions'].includes(pivotSelectedKey),
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
                isVisible: ['Données', 'Documents', 'Diffusions'].includes(pivotSelectedKey),
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer la mission',
                        subTitle: 'Êtes-vous certain de vouloir supprimer la mission ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.missionsHandlerRemoveById = missionsHandler.removeById(match?.params?.missionId)
                                    await this.missionsHandlerRemoveById.fetch()
                                    history.push('/missions')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.missionId,
                isVisible: ['Données'].includes(pivotSelectedKey),
            },
            {
                key: 'cancelMail',
                text: 'Annuler',
                iconProps: { iconName: 'MailUndelivered' },
                onClick: () => this.setState({ isReadOnly: true, isSendMailEnable: false }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandRead)
                }),
                isVisible: ['Candidatures'].includes(pivotSelectedKey) && isSendMailEnable,
            },
            {
                key: 'sendmail',
                text: 'Envoyer email',
                iconProps: { iconName: 'MailForward' },
                onClick: () => this.setState({ isSendMailModalVisible: true }),
                isVisible: ['Candidatures'].includes(pivotSelectedKey) && isSendMailEnable,
            },
        ].filter(x => x.isVisible)
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, missionsHandler, setMessageBar, me,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.missionId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.mission) // If object came from history push with a create
                    this.setState({
                        item: location.state?.mission,
                        iniItem: location.state?.mission,
                        status: Status.RESOLVED,
                    }, () => {
                        this.setupBreadcrumb()
                        this.setupCommandBar()
                        setCommand([])
                        setCommand(this.commandRead)
                    })
                else
                    try {
                        this.missionsHandlerGetById = missionsHandler.getById(match?.params?.missionId)
                        const mission = await this.missionsHandlerGetById.fetch()
                        this.setState({
                            item: mission,
                            iniItem: mission,
                            status: Status.RESOLVED,
                        }, () => {
                            this.setupBreadcrumb()
                            this.setupCommandBar()
                            setCommand([])
                            setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Mission({ userCollaboratorId: me.profileId === EProfile.Consultant ? me.userId : undefined }),
                    iniItem: new Mission({ userCollaboratorId: me.profileId === EProfile.Consultant ? me.userId : undefined }),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, missionsHandler, match, setMessageBar,
        } = this.props
        const { item, pivotSelectedKey } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                const mission = await (() => {
                    switch (pivotSelectedKey) {
                        case 'Données':
                            this.missionsHandlerUpsert = missionsHandler.upsert(item, match?.params?.missionId)
                            return this.missionsHandlerUpsert.fetch()
                        case 'Diffusions':
                            this.missionsHandlerUpsert = missionsHandler.updateWebsiteInfo(item, match?.params?.missionId)
                            return this.missionsHandlerUpsert.fetch()
                        default:
                            return item
                    }
                })()

                this.setState({
                    item: mission,
                    iniItem: mission,
                    status: Status.RESOLVED,
                    errorField: new ErrorMission(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.missionId)
                    history.replace(`/missions/${mission.missionId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorMission>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item,
            status,
            isReadOnly,
            errorField,
            pivotSelectedKey,
            isSendMailEnable,
            isSendMailModalVisible,
            eventClientsColumns,
            eventCandidatesColumn,
            isHiddingReject,
        } = this.state
        const {
            param, searchFiltersHandler, missionsHandler, match, me, candidaciesHandler, huntsHandler, emailTemplatesHandler, setCommand, location,
        } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Pivot
                    styles={{ itemContainer: { paddingTop: '20px' } }}
                    selectedKey={pivotSelectedKey}
                    onLinkClick={pivotItem => this.setState({ pivotSelectedKey: pivotItem.props.itemKey })}
                >
                    <PivotItem
                        headerText="Candidatures"
                        itemIcon="UserEvent"
                        itemKey="Candidatures"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotCandidacy
                            param={param}
                            item={item}
                            candidaciesHandler={candidaciesHandler}
                            me={me}
                            onChange={(data, isIniItemToo) => {
                                if (!isIniItemToo)
                                    this.setState(prevState => ({ item: { ...prevState.item, ...data } }))
                                else
                                    this.setState(prevState => ({
                                        item: { ...prevState.item, ...data },
                                        iniItem: { ...prevState.iniItem, ...data },
                                    }))
                            }}
                            huntsHandler={huntsHandler}
                            isSendMailEnable={isSendMailEnable}
                            setIsSendMailEnable={newVal => this.setState({ isSendMailEnable: newVal, isReadOnly: !newVal }, () => {
                                this.setupCommandBar()
                                if (newVal)
                                    setCommand(this.commandEdit)
                                else
                                    setCommand(this.commandRead)
                            })}
                            isSendMailModalVisible={isSendMailModalVisible}
                            setIsSendMailModalVisible={newVal => this.setState({ isSendMailModalVisible: newVal })}
                            emailTemplatesHandler={emailTemplatesHandler}
                            preSelectedBoardItem={location.state?.preSelectedBoardItem}
                            isHiddingReject={isHiddingReject}
                            missionsHandler={missionsHandler}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Évènements"
                        itemIcon="Event"
                        itemKey="Évènements"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotEvent
                            item={item}
                            status={status}
                            eventClientsColumns={eventClientsColumns}
                            eventCandidatesColumn={eventCandidatesColumn}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Données"
                        itemIcon="PageData"
                        itemKey="Données"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotDetail
                            isReadOnly={isReadOnly}
                            param={param}
                            item={item}
                            errorField={errorField}
                            status={status}
                            searchFiltersHandler={searchFiltersHandler}
                            match={match}
                            me={me}
                            onChange={(data, isIniItemToo) => {
                                if (!isIniItemToo)
                                    this.setState(prevState => ({ item: { ...prevState.item, ...data } }))
                                else
                                    this.setState(prevState => ({
                                        item: { ...prevState.item, ...data },
                                        iniItem: { ...prevState.iniItem, ...data },
                                    }))
                            }}
                            clear={() => this.setState({ isReadOnly: true }, () => this.setState({ isReadOnly: false }))}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Historiques"
                        itemIcon="History"
                        itemKey="Historiques"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotHistoric
                            item={item}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Diffusions"
                        itemIcon="Share"
                        itemKey="Diffusions"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotDiffusion
                            item={item}
                            missionsHandler={missionsHandler}
                            onChange={(data, isIniItemToo) => {
                                if (!isIniItemToo)
                                    this.setState(prevState => ({ item: { ...prevState.item, ...data } }))
                                else
                                    this.setState(prevState => ({
                                        item: { ...prevState.item, ...data },
                                        iniItem: { ...prevState.iniItem, ...data },
                                    }))
                            }}
                            isReadOnly={isReadOnly}
                            errorField={errorField}
                            match={match}
                            me={me}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Documents"
                        itemIcon="DocumentSet"
                        itemKey="Documents"
                        headerButtonProps={!isReadOnly ? { disabled: true, style: { color: 'grey' } } : {}}
                    >
                        <PivotDocuments
                            isReadOnly={isReadOnly}
                            item={item}
                            missionsHandler={missionsHandler}
                            onChange={(data, isIniItemToo) => {
                                if (!isIniItemToo)
                                    this.setState(prevState => ({ item: { ...prevState.item, ...data } }))
                                else
                                    this.setState(prevState => ({
                                        item: { ...prevState.item, ...data },
                                        iniItem: { ...prevState.iniItem, ...data },
                                    }))
                            }}
                        />
                    </PivotItem>
                </Pivot>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdMissions.prototype.isValidDate = isValidDate
IdMissions.prototype.onColumnClick = onColumnClick
