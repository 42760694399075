import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, buildColumns, Text, IColumn, TextField, Label, DefaultButton,
} from '@fluentui/react'
import history from 'helpers/history'
import { getUpdatedList, handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import CommercialInformation from 'requests/objects/commercialInformation'
import { Link } from 'react-router-dom'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/informationsCommerciales/index.module.scss'
import { NEW_PATH } from 'types/others'
import commercialInformationColumns, {
    commercialInformationColumnsWithoutCR,
} from 'types/informationsCommerciales/columns'
import { Columns } from 'react-bulma-components'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import ECommercialInformationProcessOption from 'types/informationsCommerciales/commercialInformationProcessOption'
import EProfile from 'types/profile'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/** @debug {AppProps} */

const MONTHES = [
    { key: 1, text: 'Janvier' },
    { key: 2, text: 'Février' },
    { key: 3, text: 'Mars' },
    { key: 4, text: 'Avril' },
    { key: 5, text: 'Mai' },
    { key: 6, text: 'Juin' },
    { key: 7, text: 'Juillet' },
    { key: 8, text: 'Aout' },
    { key: 9, text: 'Septembre' },
    { key: 10, text: 'Octobre' },
    { key: 11, text: 'Novembre' },
    { key: 12, text: 'Décembre' },
]

/**
 * @typedef {object} SearchParamsType
 * @property {number} consultantId consultantId
 * @property {number} clientId clientId
 * @property {string} prospectName prospectName
 * @property {number} operationsManagerId operationsManagerId
 * @property {number[]} statusIds statusIds
 * @property {number} monthId monthId
 */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexCommercialInformations extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {CommercialInformation[]} Items found in API */
            items: [],
            /** @type {CommercialInformation[]} Initial items found in API */
            iniItems: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                consultantId: null,
                clientId: null,
                prospectName: null,
                operationsManagerId: null,
                statusIds: [],
                monthId: null,
            },
        }

        this.submitInput = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }
        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.commercialInformationsHandler?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, setTitle } = this.props

        setBreadcrumb([
            { text: 'Saisie information commerciale', key: 'information-commerciale' },
            { text: 'Toutes les informations commerciales', key: 'all-information-commerciale', isCurrentItem: true },
        ])
        setTitle('Informations Commerciales')
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand, me } = this.props
        setCommand(
            [
                {
                    key: 'new',
                    text: 'Nouveau',
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/informations-commerciales/${NEW_PATH}`),
                    disabled: me?.profileId === EProfile.Consultant,
                },
            ],
        )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand, me } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        this.search()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    consultantId: me.profileId !== EProfile.Consultant && queryParams.get('consultantId')
                        ? +queryParams.get('consultantId') : null,
                    clientId: queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                    prospectName: queryParams.get('prospectName') || null,
                    operationsManagerId: me.profileId !== EProfile.OperationsManager && queryParams.get('operationsManagerId')
                        ? +queryParams.get('operationsManagerId') : null,
                    statusIds: queryParams.getAll('statusIds') ? queryParams.getAll('statusIds').map(x => +x) : [],
                    monthId: queryParams.get('monthId') ? +queryParams.get('monthId') : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
        this.setState({ columns: this.buildColumns() })
    }

    /**
     * Init <DetailList>
     * @returns {IColumn[]} Columns
     */
    buildColumns() {
        const { me } = this.props

        const selectedColumns = me.profileId === EProfile.OperationsManager
            ? commercialInformationColumnsWithoutCR : commercialInformationColumns

        const cols = selectedColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case 'CR':
                    case 'Consultant':
                    case 'Département':
                    case 'Nom/Prénom':
                    case 'Téléphone':
                    case 'Fonction':
                    case 'Commentaire':
                    case 'Entretien':
                    case 'Présent dans ATS':
                    case 'Non présent dans ATS':
                    default:
                        return 1100 / (columns.length ?? 1)
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 6
            column.onRender = selectedColumns.find(x => x.name === column.name)?.onRender ?? this.handleRenderColumn(selectedColumns, column.name, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { commercialInformationsHandler, setBreadcrumb } = this.props
            const { searchParams } = this.state
            setBreadcrumb([
                { text: 'Saisie information commerciale', key: 'information-commerciale' },
                { text: 'Toutes les informations commerciales', key: 'all-information-commerciale', isCurrentItem: true },
            ])
            try {
                this.commercialInformationsHandler = commercialInformationsHandler.getAll(searchParams)
                const commercialInformations = await this.commercialInformationsHandler.fetch()
                this.setState({
                    items: commercialInformations.map(x => this.flattenObj(x)),
                    iniItems: commercialInformations,
                    status: Status.RESOLVED,
                })
                setBreadcrumb([
                    { text: 'Saisie information commerciale', key: 'information-commerciale' },
                    {
                        text: `Toutes les informations commerciales (${commercialInformations.length || 0})`,
                        key: 'all-information-commerciale',
                        isCurrentItem: true,
                    },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            iniItems: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items, searchParams,
            iniItems, // eslint-disable-line no-unused-vars
        } = this.state
        const { searchFiltersHandler, param, me } = this.props

        return (
            <main className={styles.index}>
                <form
                    className={styles['index-search-filters']}
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualComboboxAjax
                                label="Client"
                                minChar={1}
                                disabled={status === Status.PENDING}
                                selectedKey={searchParams.clientId}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, clientId: option.key } })}
                                functionName="searchClients"
                                handler={searchFiltersHandler}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Prospect"
                                disabled={status === Status.PENDING}
                                value={searchParams.prospectName || ''}
                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, prospectName: newVal } })}
                            />
                        </Columns.Column>
                        {me.profileId !== EProfile.OperationsManager && (
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="CR"
                                    options={param.operationsManagers}
                                    selectedKey={searchParams.operationsManagerId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, operationsManagerId: option.key } })}
                                    disabled={status === Status.PENDING}
                                />
                            </Columns.Column>
                        )}
                        {me.profileId !== EProfile.Consultant && (
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Consultant"
                                    options={param.consultants.filter(x => !x.hasLeft)}
                                    selectedKey={searchParams.consultantId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, consultantId: option.key } })}
                                    disabled={status === Status.PENDING}
                                />
                            </Columns.Column>
                        )}
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Statut(s)"
                                options={[
                                    { key: 0, text: 'Non traité' },
                                    ...param.commercialInformationProcessOptions,
                                ]}
                                selectedKey={searchParams.statusIds}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, statusIds: this.getUpdatedList(searchParams.statusIds, option) },
                                })}
                                multiSelect
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Mois de l'entretien"
                                options={MONTHES}
                                selectedKey={searchParams.monthId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, monthId: option.key },
                                })}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <Label className="is-hidden-mobile">&nbsp;</Label>
                            <DefaultButton
                                text="Rechercher"
                                primary
                                split
                                disabled={status === Status.PENDING}
                                onClick={() => this.submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                                menuProps={{
                                    items: [
                                        {
                                            key: 'Search',
                                            text: 'Rechercher',
                                            iconProps: { iconName: 'Search' },
                                            onClick: () => this.submitInput.current.click(),
                                        },
                                        {
                                            key: 'Reset',
                                            text: 'Réinitialiser',
                                            iconProps: { iconName: 'Filter' },
                                            onClick: () => this.setState({
                                                /** @type {SearchParamsType} */
                                                searchParams: {
                                                    consultantId: null,
                                                    clientId: null,
                                                    prospectName: null,
                                                    operationsManagerId: null,
                                                    statusIds: [],
                                                    monthId: null,
                                                },
                                                items: [],
                                                iniItems: [],
                                            }),
                                        },
                                    ],
                                }}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => {
                            let rootStyle = {}
                            const { item } = props
                            if (props.item.isProcessed)
                                // eslint-disable-next-line max-len
                                if (item.commercialInformationProcessOptionId === ECommercialInformationProcessOption.ClientRdvtDone)
                                    rootStyle = {
                                        background: '#dff6dd',
                                        '&:hover': {
                                            backgroundColor: '#ccf1c9',
                                            color: 'rgb(50, 49, 48)',
                                        },
                                    }
                                else
                                    rootStyle = {
                                        background: '#fde7e9',
                                        '&:hover': {
                                            backgroundColor: '#fbcfd3',
                                            color: 'rgb(50, 49, 48)',
                                        },
                                    }
                            return (
                                <Link
                                    to={{
                                        pathname: `/informations-commerciales/${item?.commercialInformationId}`,
                                        // state: {
                                        //     commercialInformation: iniItems.find(x => x.commercialInformationId === item?.commercialInformationId),
                                        // },
                                    }}
                                >
                                    {defaultRender({ ...props, styles: { root: rootStyle } })}
                                </Link>
                            )
                        }}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
                    {!items?.length && [Status.IDLE].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Veuillez lancer une recherche</Text>}
                </Card>
            </main>
        )
    }
}

IndexCommercialInformations.prototype.onColumnClick = onColumnClick
IndexCommercialInformations.prototype.flattenObj = flattenObj
IndexCommercialInformations.prototype.handleRenderColumn = handleRenderColumn
IndexCommercialInformations.prototype.getUpdatedList = getUpdatedList
