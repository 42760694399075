import React, { PureComponent } from 'react'
import { Columns } from 'react-bulma-components'
import {
    ConstrainMode, DetailsListLayoutMode, IconButton, List, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import Card from 'components/containers/card'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import styles from 'styles/pages/index/index.module.scss'
import HomeCardTitle from 'components/pages/index/homeCardTitle'
import Time from 'helpers/methods/time'
import Mission from 'requests/objects/mission'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import HomeTop from 'components/pages/index/homeTop'
import HomeNote from 'components/pages/index/homeNote'
import HomeCandidacyCard from 'components/pages/index/homeCandidacyCard'
import Status from 'types/status'
import Home from 'requests/objects/home'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import Loader from 'components/visuals/loader'
import EProfile from 'types/profile'
import EBoardColumn from 'types/missions/enums/boardColumn'
import Hunt from 'requests/objects/hunt'
import { isValidDate, sortItems } from 'helpers/methods/common'
import getPrice from 'helpers/methods/getPrice'
import flattenObj from 'helpers/methods/flattenObj'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}}
 */
export default class Index extends PureComponent {
    constructor(props) {
        super(props)

        const MISSIONS_BASE_COLUMNS = [
            {
                key: 'ref',
                name: 'Référence',
                fieldName: 'ref',
                minWidth: 50,
                maxWidth: 75,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this.onColumnClick.bind(this),
                isSorted: false,
                isSortedDescending: true,
            },
            {
                key: 'client',
                name: 'Client',
                fieldName: 'client.name',
                minWidth: 90,
                maxWidth: 150,
                isRowHeader: true,
                isResizable: true,
                onRender: colItem => colItem['client.name'] || '',
                onColumnClick: this.onColumnClick.bind(this),
                isSorted: false,
                isSortedDescending: true,
            },
            {
                key: 'name',
                name: 'Nom',
                fieldName: 'name',
                minWidth: 150,
                maxWidth: 250,
                isRowHeader: true,
                isResizable: true,
                onRender: colItem => colItem.name ?? '',
                onColumnClick: this.onColumnClick.bind(this),
                isSorted: false,
                isSortedDescending: true,
            },
            {
                key: 'trigram',
                name: 'Trigrame',
                fieldName: 'userOperationsManager.code',
                minWidth: 50,
                maxWidth: 50,
                isRowHeader: true,
                isResizable: true,
                onRender: colItem => colItem['userOperationsManager.code'] || '',
                onColumnClick: this.onColumnClick.bind(this),
                isSorted: false,
                isSortedDescending: true,
            },
            {
                key: 'createdAt',
                name: 'Date création',
                fieldName: 'createdAt',
                minWidth: 75,
                maxWidth: 75,
                isRowHeader: true,
                isResizable: true,
                onRender: colItem => (colItem.createdAt ? Time(colItem.createdAt).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
                onColumnClick: this.onColumnClick.bind(this),
                isSorted: false,
                isSortedDescending: true,
            },
            {
                key: 'possibleAmount',
                name: 'Portefeuille',
                fieldName: 'possibleAmount',
                minWidth: 60,
                maxWidth: 75,
                isRowHeader: true,
                isResizable: true,
                onRender: colItem => (colItem.possibleAmount ? getPrice(colItem.possibleAmount) : ''),
                onColumnClick: this.onColumnClick.bind(this),
                isSorted: false,
                isSortedDescending: true,
            },
        ]

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Home} Home item */
            item: new Home(),
            isRoadmapFull: false,
            isDispatchFull: false,
            isClosedFull: false,
            isInProgressFull: false,
            runningMissionsColumns: [...MISSIONS_BASE_COLUMNS],
            closedMissionsColumns: [...MISSIONS_BASE_COLUMNS],
            dispatchMissionsColumns: [...MISSIONS_BASE_COLUMNS],
        }
    }

    componentDidMount() {
        const {
            setMessageBar, setBreadcrumb, setCommand, setTitle,
        } = this.props

        setMessageBar({ isDisplayed: false })
        setBreadcrumb([])
        setCommand([])
        setTitle('Accueil')

        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { homesHandler } = this.props
            try {
                this.homesHandlerGet = homesHandler.get()
                const home = await this.homesHandlerGet.fetch()
                this.setState({
                    // Remove stuff without coordinates
                    item: {
                        ...home,
                        runningMissions: home.runningMissions?.map(x => flattenObj(x)) ?? [],
                        closedMissions: home.closedMissions?.map(x => flattenObj(x)) ?? [],
                        dispatchMissions: home.dispatchMissions?.map(x => flattenObj(x)) ?? [],
                        runningWithNewCandidacyMissions: home.runningWithNewCandidacyMissions?.map(x => flattenObj(x)) ?? [],
                    },
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            item: new Home(),
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    componentWillUnmount() {
        this.homesHandlerGet?.cancel?.()
    }

    onColumnClick(_ev, column) {
        const {
            runningMissionsColumns,
            closedMissionsColumns,
            dispatchMissionsColumns,
            isDispatchFull,
            isClosedFull,
            isInProgressFull, item,
        } = this.state

        const key = (() => {
            if (isDispatchFull)
                return 'dispatchMissions'
            if (isClosedFull)
                return 'closedMissions'
            if (isInProgressFull)
                return 'runningMissions'
            return ''
        })()

        const newColumns = (() => {
            if (isDispatchFull)
                return dispatchMissionsColumns.slice()
            if (isClosedFull)
                return closedMissionsColumns.slice()
            if (isInProgressFull)
                return runningMissionsColumns.slice()
            return []
        })()

        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0]

        newColumns.forEach(newCol => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending
                currColumn.isSorted = true
            } else {
                const colCopy = { ...newCol } // Create a copy of newCol
                colCopy.isSorted = false
                colCopy.isSortedDescending = true
                // Update the array element with the modified copy
                Object.assign(newColumns, { [newColumns.indexOf(newCol)]: colCopy })
            }
        })

        const newItems = sortItems(item[key], currColumn.fieldName, currColumn.isSortedDescending)

        this.setState({
            [`${key}Columns`]: newColumns,
            item: { ...item, [key]: newItems },
        })
    }

    /**
     * Get a row style for mission
     * @param {Mission} item mission
     * @returns {import('@fluentui/react').IStyleFunctionOrObject<
     *  import('@fluentui/react').IDetailsRowStyleProps,
     *  import('@fluentui/react').IDetailsRowStyles
     * >} Style
     */
    // eslint-disable-next-line class-methods-use-this
    getRowStyle(item) {
        const sixMonthAgo = new Date()
        sixMonthAgo.setMonth(sixMonthAgo.getMonth() - 6)
        return {
            // eslint-disable-next-line no-nested-ternary
            root: item.isBackout
                ? {
                    background: '#c7e0f4',
                    '&:hover': {
                        backgroundColor: '#deecf9',
                        color: 'rgb(50, 49, 48)',
                    },
                }
                : (item.createdAt && item.createdAt?.getTime() < sixMonthAgo.getTime()
                    ? {
                        background: '#fde7e9',
                        '&:hover': {
                            backgroundColor: '#fbcfd3',
                            color: 'rgb(50, 49, 48)',
                        },
                    }
                    : undefined),
        }
    }

    render() {
        const {
            runningMissionsColumns, closedMissionsColumns, dispatchMissionsColumns, item, status, isRoadmapFull, isDispatchFull, isClosedFull, isInProgressFull,
        } = this.state
        const { me } = this.props
        return (
            <main className={styles.index}>
                <HomeTop
                    me={me}
                    currentWallet={item.currentWallet}
                    forecast={item.forecast}
                    monthlyTurnover={item.monthlyTurnover}
                    annualTurnover={item.annualTurnover}
                    totalTurnover={item.totalTurnover}
                    clientVisitsTotal={item.clientVisitsTotal}
                />
                {status === Status.PENDING
                    ? <Loader />
                    : (
                        <Columns>
                            <Columns.Column
                                className={classNames(styles['index-left-container'], 'is-half-widescreen', 'is-full')}
                            >
                                {[
                                    EProfile.Administrator,
                                    EProfile.Consultant,
                                    EProfile.OperationsManager,
                                ].includes(me.profileId)
                                    && !isDispatchFull
                                    && !isClosedFull
                                    && (
                                        <Card
                                            title={(
                                                <HomeCardTitle
                                                    leftText="Mission(s) en cours"
                                                    rightText={(
                                                        <>
                                                            mission(s)
                                                            <IconButton
                                                                iconProps={{ iconName: isInProgressFull ? 'Remove' : 'Add' }}
                                                                onClick={() => this.setState({ isInProgressFull: !isInProgressFull })}
                                                                styles={{ root: { margin: '-3px 0 -3px 5px' } }}
                                                                title={isInProgressFull ? 'Voir moins' : 'Voir plus'}
                                                            />
                                                        </>
                                                    )}
                                                    rightNumber={item.runningMissions?.length ?? 0}
                                                    variant="grey-blue"
                                                    tooltipText="Mission(s) en cours avec un chargé de recrutement"
                                                />
                                            )}
                                            className={classNames(
                                                styles['index-left-container-card'],
                                                styles['is-grey-blue'],
                                                { [styles['is-two-thirds']]: [EProfile.OperationsManager].includes(me.profileId) },
                                            )}
                                            iconName="Refresh"
                                        >
                                            <div className={classNames(styles['index-left-container-card-container'])}>
                                                {item.runningMissions?.length > 0
                                                    && (
                                                        <ShimmeredDetailsList
                                                            items={item.runningMissions}
                                                            columns={runningMissionsColumns}
                                                            selectionMode={SelectionMode.none}
                                                            onShouldVirtualize={() => true}
                                                            layoutMode={DetailsListLayoutMode.justified}
                                                            constrainMode={ConstrainMode.unconstrained}
                                                            onRenderDetailsHeader={isInProgressFull
                                                                ? (props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })
                                                                : () => null}
                                                            // eslint-disable-next-line react/no-unstable-nested-components
                                                            onRenderRow={(props, defaultRender) => (
                                                                <Link to={`/missions/${props.item?.missionId}`}>
                                                                    {defaultRender({
                                                                        ...props,
                                                                        styles: this.getRowStyle(props.item),
                                                                    })}
                                                                </Link>
                                                            )}
                                                        />
                                                    )}
                                                {!item.runningMissions?.length
                                                    && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                            </div>
                                        </Card>
                                    )}
                                {[EProfile.Consultant].includes(me.profileId) && !isDispatchFull && !isClosedFull && !isInProgressFull && (
                                    <Card
                                        title={(
                                            <HomeCardTitle
                                                leftText="Mission(s) en cours ayant candidature(s) en réception"
                                                rightText="mission(s)"
                                                rightNumber={item.runningWithNewCandidacyMissions?.length ?? 0}
                                                variant="grey-blue"
                                                tooltipText="Candidature(s) en cours, placée(s) dans la colonne 'Réception retour annonces'.
                                                 Ces candidatures ne sont pas rejetées ni supprimées"
                                            />
                                        )}
                                        className={classNames(
                                            styles['index-left-container-card'],
                                            styles['is-grey-blue'],
                                            { [styles['is-two-thirds']]: [EProfile.OperationsManager].includes(me.profileId) },
                                        )}
                                        iconName="ToDoLogoOutline"
                                    >
                                        <div className={classNames(styles['index-left-container-card-container'])}>
                                            {item.runningWithNewCandidacyMissions?.length > 0
                                                && (
                                                    <ShimmeredDetailsList
                                                        items={item.runningWithNewCandidacyMissions}
                                                        columns={runningMissionsColumns}
                                                        selectionMode={SelectionMode.none}
                                                        onShouldVirtualize={() => true}
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        constrainMode={ConstrainMode.unconstrained}
                                                        onRenderDetailsHeader={() => null}
                                                        // eslint-disable-next-line react/no-unstable-nested-components
                                                        onRenderRow={(props, defaultRender) => (
                                                            <Link to={`/missions/${props.item?.missionId}`}>
                                                                {defaultRender({
                                                                    ...props,
                                                                    styles: this.getRowStyle(props.item),
                                                                })}
                                                            </Link>
                                                        )}
                                                    />
                                                )}
                                            {!item.runningWithNewCandidacyMissions?.length
                                                && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                        </div>
                                    </Card>
                                )}
                                {[EProfile.Administrator].includes(me.profileId) && !isDispatchFull && !isInProgressFull && (
                                    <Card
                                        title={(
                                            <HomeCardTitle
                                                leftText="Mission(s) clôturée(s) de la semaine"
                                                emoji={item.closedMissions?.length > 5 ? '😀' : '🙁'}
                                                rightText={(
                                                    <>
                                                        mission(s)
                                                        <IconButton
                                                            iconProps={{ iconName: isClosedFull ? 'Remove' : 'Add' }}
                                                            onClick={() => this.setState({ isClosedFull: !isClosedFull })}
                                                            styles={{ root: { margin: '-3px 0 -3px 5px' } }}
                                                            title={isClosedFull ? 'Voir moins' : 'Voir plus'}
                                                        />
                                                    </>
                                                )}
                                                rightNumber={item.closedMissions?.length ?? 0}
                                                variant="purple"
                                                tooltipText="Mission dont la date de modification est inférieure
                                                à 7 sept jours, et où le statut est placé ou interrompu"
                                            />
                                        )}
                                        className={classNames(styles['index-left-container-card'], styles['is-purple'])}
                                        iconName="CircleStop"
                                    >
                                        <div className={classNames(styles['index-left-container-card-container'])}>
                                            {item.closedMissions?.length > 0
                                                && (
                                                    <ShimmeredDetailsList
                                                        items={item.closedMissions}
                                                        columns={closedMissionsColumns}
                                                        selectionMode={SelectionMode.none}
                                                        onShouldVirtualize={() => true}
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        constrainMode={ConstrainMode.unconstrained}
                                                        onRenderDetailsHeader={isClosedFull
                                                            ? (props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })
                                                            : () => null}
                                                        // eslint-disable-next-line react/no-unstable-nested-components
                                                        onRenderRow={(props, defaultRender) => (
                                                            <Link to={`/missions/${props.item?.missionId}`}>
                                                                {defaultRender({
                                                                    ...props,
                                                                    styles: this.getRowStyle(props.item),
                                                                })}
                                                            </Link>
                                                        )}
                                                    />
                                                )}
                                            {!item.closedMissions?.length
                                                && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                        </div>
                                    </Card>
                                )}
                                {[EProfile.Administrator].includes(me.profileId) && !isClosedFull && !isInProgressFull && (
                                    <Card
                                        title={(
                                            <HomeCardTitle
                                                leftText="Mission(s) à dispatcher"
                                                rightText={(
                                                    <>
                                                        mission(s)
                                                        <IconButton
                                                            iconProps={{ iconName: isDispatchFull ? 'Remove' : 'Add' }}
                                                            onClick={() => this.setState({ isDispatchFull: !isDispatchFull })}
                                                            styles={{ root: { margin: '-3px 0 -3px 5px' } }}
                                                            title={isDispatchFull ? 'Voir moins' : 'Voir plus'}
                                                        />
                                                    </>
                                                )}
                                                rightNumber={item.dispatchMissions?.length ?? 0}
                                                variant="light-blue"
                                                tooltipText="Mission(s) sans aucun chargé de recrutement"
                                            />
                                        )}
                                        className={classNames(styles['index-left-container-card'], styles['is-light-blue'])}
                                        iconName="Share"
                                    >
                                        <div className={classNames(styles['index-left-container-card-container'])}>
                                            {item.dispatchMissions?.length > 0
                                                && (
                                                    <ShimmeredDetailsList
                                                        items={item.dispatchMissions}
                                                        columns={dispatchMissionsColumns}
                                                        selectionMode={SelectionMode.none}
                                                        onShouldVirtualize={() => true}
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        constrainMode={ConstrainMode.unconstrained}
                                                        onRenderDetailsHeader={isDispatchFull
                                                            ? (props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })
                                                            : () => null}
                                                        // eslint-disable-next-line react/no-unstable-nested-components
                                                        onRenderRow={(props, defaultRender) => (
                                                            <Link
                                                                to={{
                                                                    pathname: `/missions/${props.item?.missionId}`,
                                                                    state: {
                                                                        pivotSelectedKey: 'Données',
                                                                    },
                                                                }}
                                                            >
                                                                {defaultRender({
                                                                    ...props,
                                                                    styles: this.getRowStyle(props.item),
                                                                })}
                                                            </Link>
                                                        )}
                                                    />
                                                )}
                                            {!item.dispatchMissions?.length
                                                && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                        </div>
                                    </Card>
                                )}
                            </Columns.Column>
                            <Columns.Column
                                className={classNames(styles['index-right-container'], 'is-half-widescreen', 'is-full')}
                            >
                                {!isRoadmapFull && (
                                    <div
                                        className={classNames(styles['index-right-container-candidates'])}
                                    >
                                        <Card
                                            iconName="Timer"
                                            title={(
                                                <HomeCardTitle
                                                    leftText="Candidature(s) entretien"
                                                    emoji={item.expiredCandidacies?.length > 0 ? '🙁' : '😀'}
                                                    tooltipText="Candidature(s) non rejetée(s), mais dont l'entretien est déjà passé depuis 1 journée
                                                    ou l'entretien n'a pas été validé"
                                                />
                                            )}
                                        >
                                            <div
                                                className={classNames(styles['index-right-container-candidates-container'])}
                                            >
                                                <List
                                                    items={item.expiredCandidacies}
                                                    renderedWindowsAhead={5}
                                                    renderedWindowsBehind={5}
                                                    // eslint-disable-next-line react/no-unstable-nested-components
                                                    onRenderCell={(candidacy, index) => (
                                                        <HomeCandidacyCard
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`expiredCandidacies_${index}`}
                                                            item={candidacy}
                                                            footerText={candidacy.interviewStartDate
                                                                ? `Entretien le ${Time(candidacy.interviewStartDate).getCleanDate()}`
                                                                : ''}
                                                            position={1}
                                                        />
                                                    )}
                                                />
                                                {!item.expiredCandidacies?.length
                                                    && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                            </div>
                                        </Card>
                                        <Card
                                            iconName="MailAttached"
                                            title={(
                                                <HomeCardTitle
                                                    leftText={'Candidature(s) "Envoi de CV"'}
                                                    emoji={item.sendingCvCandidacies?.length > 0 ? '🙁' : '😀'}
                                                    tooltipText="Candidature(s) placée(s) sur la colonne 'Envoie du CV'
                                                    il y a au moins 6 jours et qui ne sont pas rejetées"
                                                />
                                            )}
                                        >
                                            <div
                                                className={classNames(styles['index-right-container-candidates-container'])}
                                            >
                                                <List
                                                    items={item.sendingCvCandidacies}
                                                    renderedWindowsAhead={5}
                                                    renderedWindowsBehind={5}
                                                    // eslint-disable-next-line react/no-unstable-nested-components
                                                    onRenderCell={(candidacy, index) => (
                                                        <HomeCandidacyCard
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`sendingCvCandidacies_${index}`}
                                                            item={candidacy}
                                                            footerText={(() => {
                                                                const dt = candidacy.boardHistorics
                                                                    .find(x => x.currentBoardColumnId === EBoardColumn.SENDING_CV)?.createdAt
                                                                if (dt && isValidDate(dt))
                                                                    return `Cv envoyé le ${Time(dt).getCleanDate()}`
                                                                return ''
                                                            })()}
                                                            position={2}
                                                        />
                                                    )}
                                                />
                                                {!item.sendingCvCandidacies?.length
                                                    && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                            </div>
                                        </Card>
                                        <Card
                                            iconName="OfficeChat"
                                            title={(
                                                <HomeCardTitle
                                                    leftText={'Candidature(s) "Entretien client"'}
                                                    emoji={item.interviewCandidacies?.length > 0 ? '🙁' : '😀'}
                                                    tooltipText="Candidature(s) non rejetée(s), n'étant pas sous propale.
                                                    L'entretien des candidats s'est déroulé il y a au moins 3 jours"
                                                />
                                            )}
                                        >
                                            <div
                                                className={classNames(styles['index-right-container-candidates-container'])}
                                            >
                                                <List
                                                    items={item.interviewCandidacies}
                                                    renderedWindowsAhead={5}
                                                    renderedWindowsBehind={5}
                                                    // eslint-disable-next-line react/no-unstable-nested-components
                                                    onRenderCell={(candidacy, index) => (
                                                        <HomeCandidacyCard
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={`interviewCandidacies_${index}`}
                                                            item={candidacy}
                                                            footerText={candidacy.interviewClientStartDate
                                                                ? `Entretien client le ${Time(candidacy.interviewClientStartDate).getCleanDate()}`
                                                                : ''}
                                                            position={3}
                                                        />
                                                    )}
                                                />
                                                {!item.interviewCandidacies?.length
                                                    && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                                            </div>
                                        </Card>
                                    </div>
                                )}
                                <Card
                                    title={(
                                        <HomeCardTitle
                                            leftText="Feuille de route de la journée"
                                            rightText={(
                                                <IconButton
                                                    iconProps={{ iconName: isRoadmapFull ? 'Remove' : 'Add' }}
                                                    onClick={() => this.setState({ isRoadmapFull: !isRoadmapFull })}
                                                    styles={{ root: { margin: '-3px 0' } }}
                                                    title={isRoadmapFull ? 'Voir moins' : 'Voir plus'}
                                                />
                                            )}
                                        />
                                    )}
                                    iconName="CalendarDay"
                                    className={classNames(styles['index-right-container-roadmap'])}
                                >
                                    <div className={classNames(styles['index-right-container-roadmap-container'])}>
                                        {item.roadmaps.pinnedBoardComments?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Pense-bêtes :
                                                    </Text>
                                                    {item.roadmaps.pinnedBoardComments.map((pinnedBoardComment, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={{
                                                                // eslint-disable-next-line max-len
                                                                pathname: `/missions/${pinnedBoardComment.candidacy?.missionId ?? pinnedBoardComment.hunt?.missionId ?? ''}`,
                                                                state: {
                                                                    preSelectedBoardItem: pinnedBoardComment.candidacy ?? pinnedBoardComment.hunt,
                                                                },
                                                            }}
                                                        >
                                                            {pinnedBoardComment.candidacy?.mission?.ref ?? pinnedBoardComment.hunt?.mission?.ref ?? ''}
                                                            {' / '}
                                                            {pinnedBoardComment.candidacy?.mission?.client?.name
                                                                ?? pinnedBoardComment.hunt?.mission?.client?.name ?? ''}
                                                            {' / '}
                                                            {pinnedBoardComment.candidacy?.mission?.name ?? pinnedBoardComment.hunt?.mission?.name ?? ''}
                                                            {' - '}
                                                            {pinnedBoardComment.content ?? ''}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                        {item.roadmaps.briefs?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Briefs :
                                                    </Text>
                                                    {item.roadmaps.briefs.map((brief, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={{
                                                                pathname: `/missions/${brief.missionId}`,
                                                                state: {
                                                                    pivotSelectedKey: 'Données',
                                                                },
                                                            }}
                                                        >
                                                            {brief?.ref ?? ''}
                                                            {' / '}
                                                            {brief?.client?.name ?? ''}
                                                            {' / '}
                                                            {brief?.name ?? ''}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                        {item.roadmaps.interviews?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Rappels à faire ce jour :
                                                    </Text>
                                                    {item.roadmaps.interviews.map((interview, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={{
                                                                // eslint-disable-next-line max-len
                                                                pathname: `/missions/${interview?.missionId}`,
                                                                state: {
                                                                    preSelectedBoardItem: interview,
                                                                },
                                                            }}
                                                        >
                                                            {[interview.candidate?.lastname, interview.candidate?.firstname]
                                                                .filter(x => x).join(' ').trim() || 'Inconnu'}
                                                            {' '}
                                                            -
                                                            {' '}
                                                            {/** @type {Hunt} */(interview).company || interview.candidate?.company || 'Inconnu'}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                        {item.roadmaps.proposals?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Propales :
                                                    </Text>
                                                    {item.roadmaps.proposals.map((proposal, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={{
                                                                // eslint-disable-next-line max-len
                                                                pathname: `/missions/${proposal?.missionId}`,
                                                                state: {
                                                                    preSelectedBoardItem: proposal,
                                                                },
                                                            }}
                                                        >
                                                            {[proposal.candidate?.lastname, proposal.candidate?.firstname]
                                                                .filter(x => x).join(' ').trim() || 'Inconnu'}
                                                            {' / '}
                                                            {proposal.mission?.name || ''}
                                                            {' / '}
                                                            {proposal.mission?.client?.name || ''}
                                                            {' / '}
                                                            {proposal.mission?.ref || ''}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                        {item.roadmaps.clientVisits?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Visites clients :
                                                    </Text>
                                                    {item.roadmaps.clientVisits.map((clientVisit, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={`/clients/${clientVisit.contact.clientId}`}
                                                        >
                                                            {clientVisit?.mission?.ref ?? ''}
                                                            {' / '}
                                                            {clientVisit?.mission?.client?.name ?? ''}
                                                            {' / '}
                                                            {clientVisit?.mission?.name ?? ''}
                                                            {' - '}
                                                            {clientVisit.contact?.client?.name || 'Inconnu'}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                        {item.roadmaps.returnAnnouncementCandidacies?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Réception retour annonces :
                                                    </Text>
                                                    {item.roadmaps.returnAnnouncementCandidacies.map((candidacy, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={`/missions/${candidacy?.missionId}`}
                                                        >
                                                            {candidacy?.mission?.ref ?? ''}
                                                            {' / '}
                                                            {candidacy?.mission?.client?.name ?? ''}
                                                            {' / '}
                                                            {candidacy?.mission?.name ?? ''}
                                                            {' - '}
                                                            {[candidacy.candidate?.lastname, candidacy.candidate?.firstname]
                                                                .filter(x => x).join(' ').trim() || 'Inconnu'}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                        {item.roadmaps.todos?.length > 0
                                            && (
                                                <>
                                                    <Text style={{ fontWeight: 'bold' }}>
                                                        Mes TODO :
                                                    </Text>
                                                    {item.roadmaps.todos.map((todo, i) => (
                                                        <HomeNote
                                                            key={i} // eslint-disable-line react/no-array-index-key
                                                            to={`/clients/${todo.contact.clientId}/contacts/${todo.contactId}`}
                                                        >
                                                            {todo.contact?.client?.name || 'Inconnu'}
                                                            {' - '}
                                                            {(todo?.todoDate
                                                                ? Time(todo.todoDate).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' })
                                                                : '')}
                                                            {' - '}
                                                            {[todo?.contact?.lastname, todo?.contact?.firstname].filter(x => x).join(' ') ?? ''}
                                                            {' - '}
                                                            {todo.eventType?.name ?? ''}
                                                        </HomeNote>
                                                    ))}
                                                </>
                                            )}
                                    </div>
                                </Card>
                            </Columns.Column>
                        </Columns>
                    )}
            </main>
        )
    }
}

Index.prototype.sortItems = sortItems
