import { Icon, Text } from '@fluentui/react'
import React, { useMemo } from 'react'
import Candidacy from 'requests/objects/candidacy'
import styles from 'styles/components/pages/index/homeCandidacyCard.module.scss'
import EBoardCardType from 'types/missions/enums/boardCardType'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Hunt from 'requests/objects/hunt'
import EBoardColumn from 'types/missions/enums/boardColumn'

/**
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {string} props.footerText footerText
 * @param {1|2|3} props.position position
 * @returns {JSX.Element} Returns
 */
export default function HomeCandidacyCard({
    item, footerText, position,
}) {
    const color = useMemo(() => {
        switch (position) {
            case 1:
                if (item.interviewEndDate < new Date(new Date().setDate(new Date().getDate() - 4)))
                    return 'red'
                if (item.interviewEndDate < new Date(new Date().setDate(new Date().getDate() - 1)))
                    return 'blue'
                return ''
            case 2:
                if (
                    item.boardHistorics
                        .some(x => x.currentBoardColumnId === EBoardColumn.SENDING_CV
                            && x.createdAt < new Date(new Date().setDate(new Date().getDate() - 4)))
                )
                    return 'red'
                if (
                    item.boardHistorics
                        .some(x => x.currentBoardColumnId === EBoardColumn.SENDING_CV
                            && x.createdAt < new Date(new Date().setDate(new Date().getDate() - 1)))
                )
                    return 'blue'
                return ''
            case 3:
                if (item.interviewClientStartDate < new Date(new Date().setDate(new Date().getDate() - 15)))
                    return 'red'
                if (item.interviewClientStartDate < new Date(new Date().setDate(new Date().getDate() - 1)))
                    return 'blue'
                return ''
            default:
                return ''
        }
    }, [item.boardHistorics, item.interviewClientStartDate, item.interviewEndDate, position])

    return (
        <Link
            to={`/missions/${item.missionId}`}
            className={classNames(
                styles['home-candidacy-card'],
                { [styles['is-grey-blue']]: item.type === EBoardCardType.CANDIDACY },
                { [styles['is-purple']]: item.type === EBoardCardType.HUNT },
                { [styles['is-yellow']]: item.isUnderProposal },
                { [styles['is-red']]: color === 'red' },
                { [styles['is-blue']]: color === 'blue' },
            )}
        >
            <header className={styles['home-candidacy-card-header']}>
                <Text className={styles['home-candidacy-card-header-title']}>
                    {item.mission?.ref}
                    ,
                </Text>
                <Text className={styles['home-candidacy-card-header-type']}>
                    {item.type === EBoardCardType.CANDIDACY
                        ? (
                            <Text variant="smallPlus">
                                <Icon iconName="TextDocument" />
                                {' '}
                                Classique
                            </Text>
                        )
                        : ''}
                    {item.type === EBoardCardType.HUNT
                        ? (
                            <Text variant="smallPlus">
                                <Icon iconName="BullseyeTarget" />
                                {' '}
                                Chasse
                            </Text>
                        )
                        : ''}
                </Text>
            </header>
            <Text variant="xSmall">
                {item.mission?.name || 'Inconnu'}
                {' '}
                /
                {' '}
                {item.mission?.missionFamily?.name || 'Inconnu'}
            </Text>
            <Text variant="xSmall">
                {/** @type {Hunt} */(item).company || item.candidate?.company || 'Inconnu'}
                {' '}
                /
                {' '}
                {[item.candidate?.lastname, item.candidate?.firstname].filter(x => x).join(' ').trim() || 'Inconnu'}
            </Text>
            <footer className={styles['home-candidacy-card-footer']}>
                <Text
                    variant="smallPlus"
                    block
                >
                    {footerText}
                </Text>
            </footer>
        </Link>
    )
}
