import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton, Icon, Label, Panel, PanelType, PrimaryButton, Text, TextField, Toggle,
} from '@fluentui/react'
import Candidacy from 'requests/objects/candidacy'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Time from 'helpers/methods/time'
import CvInput from 'components/pages/candidats/[id]/cvInput'
import Divider from 'components/visuals/divider'
import { Columns } from 'react-bulma-components'
import User from 'requests/objects/user'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardDetailPanel.module.scss'
import BoardDetailPanelComment from 'components/pages/missions/[id]/pivotCandidacy/boardDetailPanelComment'
import BoardComment from 'requests/objects/boardComment'
import Hunt from 'requests/objects/hunt'
import HuntsHandler from 'requests/handlers/huntsHandler'
import EBoardCardType from 'types/missions/enums/boardCardType'
import BoardDetailPanelCvTemplateInput from 'components/pages/missions/[id]/pivotCandidacy/boardDetailPanelCvTemplateInput'
import BoardInterviewPanel from 'components/pages/missions/[id]/pivotCandidacy/boardInterviewPanel'
import BoardRejectModal from 'components/pages/missions/[id]/pivotCandidacy/boardRejectModal'
import BoardCancelledModal from 'components/pages/missions/[id]/pivotCandidacy/boardCancelledModal'
import BoardUpsertIBoardtemPanel from 'components/pages/missions/[id]/pivotCandidacy/boardUpsertIBoardtemPanel'
import Param from 'requests/objects/param'
import { Link } from 'react-router-dom'
import BoardMissionCodingModal from 'components/pages/missions/[id]/pivotCandidacy/boardMissionCodingModal'
import BoardDetailPanelFiles from 'components/pages/missions/[id]/pivotCandidacy/boardDetailPanelFiles'
import EBoardColumn from 'types/missions/enums/boardColumn'
import BoardReCodingModal from 'components/pages/missions/[id]/pivotCandidacy/boardReCodingModal'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import NotImplementedError from 'requests/errors/notImplementedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Status from 'types/status'
import classNames from 'classnames'
import BoardDeleteModal from 'components/pages/missions/[id]/pivotCandidacy/boardDeleteModal'
import HandleBlob from 'helpers/methods/blob'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {User} props.me me
 * @param {(item: Hunt | Candidacy) => void} props.onCreate onCreate
 * @param {(item: Hunt | Candidacy) => void} props.onUpdate onUpdate
 * @param {(id: number, type: EBoardCardType) => void} props.onDelete onDelete
 * @param {Param} props.param param
 * @returns {JSX.Element} Returns
 */
export default function BoardDetailPanel({
    item, isVisible, setIsVisible, handler, me, onCreate, onUpdate, param, onDelete,
}) {
    const [isInterviewPanelOpen, setIsInterviewPanelOpen] = useState(false)
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
    const [isCancelledModalVisible, setIsCancelledModalVisible] = useState(false)
    const [isUpsertBoardItemPanelOpen, setIsUpsertBoardItemPanelOpen] = useState(false)
    const [isBoardMissionCodingModalVisible, setIsBoardMissionCodingModalVisible] = useState(false)
    const [isBoardReCodingModalVisible, setIsBoardReCodingModalVisible] = useState(false)
    const [underProposalStatus, setUnderProposalStatus] = useState(Status.IDLE)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [pdfInterviewStatus, setPdfInterviewStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Candidacy | Hunt>>} */
    const handlerUpdateItem = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const handlerGetPdfInterview = useRef(null)

    const updateUnderProposal = useCallback(
        /**
         * @param {boolean} isChecked ischecked
         */
        async isChecked => {
            try {
                setUnderProposalStatus(Status.PENDING)
                handlerUpdateItem.current = handler.patchUnderProposal(
                    (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId),
                    { isUnderProposal: isChecked },
                )
                const newItem = await handlerUpdateItem.current.fetch()
                onUpdate({ ...item, isUnderProposal: newItem.isUnderProposal })
                setUnderProposalStatus(Status.RESOLVED)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case NotImplementedError:
                        break
                    case InvalidEntityError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setUnderProposalStatus(Status.REJECTED)
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        setUnderProposalStatus(Status.REJECTED)
                        break
                }
            }
        }, [item, handler, onUpdate],
    )

    /** Download interview pdf */
    const downloadInterviewPdf = useCallback(async () => {
        try {
            setPdfInterviewStatus(Status.PENDING)
            handlerGetPdfInterview.current = handler.getPdfInterview((/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId))
            const blob = await handlerGetPdfInterview.current.fetch()
            HandleBlob.download(blob, `${item.candidate.firstname}-${item.candidate.lastname}-interview.pdf`)
            setPdfInterviewStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                case InvalidEntityError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setPdfInterviewStatus(Status.REJECTED)
                    break
            }
        }
    }, [handler, item])

    useEffect(() => {
        if (isInterviewPanelOpen || isUpsertBoardItemPanelOpen || isVisible)
            document.querySelector('html').classList.add('is-scroll-disable')
        else
            document.querySelector('html').classList.remove('is-scroll-disable')
        return () => {
            document.querySelector('html').classList.remove('is-scroll-disable')
        }
    }, [isInterviewPanelOpen, isUpsertBoardItemPanelOpen, isVisible])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateItem?.current?.cancel()
    }, [])

    /**
     * Get status string based on status code.
     * @param {number} statusCode - The status code.
     * @returns {string} The corresponding status string.
     */
    const getStatusString = statusCode => {
        switch (statusCode) {
            case 1:
                return 'réception retour annonce'
            case 2:
                return 'annonce retour'
            case 3:
                return 'à appeler'
            case 4:
                return 'appelé'
            case 5:
                return 'entretien candidat'
            case 6:
                return 'envoi cv'
            case 7:
                return 'entretien client candidat'
            case 8:
                return 'placé'
            default:
                return 'statut inconnu'
        }
    }

    return (
        <>
            <Panel
                className={styles['board-detail-panel']}
                headerText={item.type === EBoardCardType.CANDIDACY ? 'Détails de la candidature' : "Détails de l'AD"}
                isOpen={isVisible}
                onDismiss={() => {
                    // Prevnet quitting is a modal is open
                    if (!isRejectModalVisible && !isBoardMissionCodingModalVisible && !isBoardReCodingModalVisible && !isDeleteModalVisible)
                        setIsVisible(false)
                }}
                closeButtonAriaLabel="Close"
                type={PanelType.custom}
                customWidth="780px"
                // eslint-disable-next-line react/no-unstable-nested-components
                onRenderFooterContent={() => (
                    <div>
                        <DefaultButton
                            onClick={() => setIsVisible(false)}
                        >
                            Fermer
                        </DefaultButton>
                    </div>
                )}
                isFooterAtBottom
            >
                <br />
                <Text
                    variant="large"
                    block
                >
                    <Icon iconName="ChevronRightMed" />
                    {' '}
                    Détails
                </Text>
                <Divider />
                <Columns>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Nom/Prénom"
                            value={[
                                item.candidate?.lastname,
                                item.candidate?.firstname,
                            ].filter(x => x).join(' ').trim().toUpperCase() || 'Inconnu'}
                            readOnly
                            borderless
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderInput={(props, defaultRender) => (item.candidateId
                                ? (
                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                        <Link to={`/candidats/${item.candidateId}`}>
                                            {props.value}
                                        </Link>
                                    </Text>
                                )
                                : defaultRender(props))}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Email"
                            value={item.candidate?.email || ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        {item.type === EBoardCardType.CANDIDACY && (
                            <TextField
                                label="Entreprise actuelle"
                                value={item?.candidate?.company || ''}
                                readOnly
                                borderless
                            />
                        )}
                        {item.type === EBoardCardType.HUNT && (
                            <TextField
                                label="Entreprise où chasser"
                                value={/** @type {Hunt} */(item).company || ''}
                                readOnly
                                borderless
                            />
                        )}
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Téléphone portable"
                            value={item.candidate?.mobilePhone || ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        {/** @type {Candidacy} */(item).origin
                            ? (
                                <TextField
                                    label="Origine"
                                    value={/** @type {Candidacy} */(item).origin}
                                    readOnly
                                    borderless
                                />
                            )
                            : (
                                <TextField
                                    label="Provenance"
                                    value={/** @type {Candidacy} */(item).candidacyProvenance?.name
                                        || /** @type {Hunt} */(item).huntProvenance?.name
                                        || ''}
                                    readOnly
                                    borderless
                                />
                            )}
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Téléphone"
                            value={item.candidate.landlinePhone || ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-third">
                        <CvInput
                            isReadOnly
                            id={/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId}
                            handler={handler}
                            updateItem={() => null}
                            item={item}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Poste actuel"
                            value={/** @type {Candidacy} */(item).candidate?.currentPosition || ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Profil linkedin"
                            value={item.candidate?.linkedinProfileUrl || ''}
                            readOnly
                            borderless
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderInput={(props, defaultRender) => (item.candidate?.linkedinProfileUrl
                                ? (
                                    <Text
                                        styles={{
                                            root: {
                                                padding: '6px 8px 0', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                                            },
                                        }}
                                    >
                                        <a
                                            href={item.candidate?.linkedinProfileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item.candidate?.linkedinProfileUrl}
                                        </a>
                                    </Text>
                                )
                                : defaultRender(props))}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-third">
                        <Label
                            htmlFor="isUnderProposal"
                            style={{ display: 'flex' }}
                        >
                            Sous propale
                        </Label>
                        <Toggle
                            id="isUnderProposal"
                            onText="Oui"
                            offText="Non"
                            checked={item.isUnderProposal}
                            onChange={(_ev, checked) => updateUnderProposal(checked)}
                            disabled={underProposalStatus === Status.PENDING}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Date et heure du début de l'entretien"
                            value={item.interviewStartDate ? Time(item.interviewStartDate).getLocaleDateTimeString() : ''}
                            readOnly
                            borderless
                            type="datetime-local"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="Date et heure du début de l'entretien client"
                            value={item.interviewClientStartDate ? Time(item.interviewClientStartDate).getLocaleDateTimeString() : ''}
                            readOnly
                            borderless
                            type="datetime-local"
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-third">
                        <TextField
                            label="RA"
                            value={item.currentSalary || ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                    <Columns.Column size="one-third">
                        <TextField
                            label="RS"
                            value={item.desiredSalary || ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <TextField
                            label="Commentaire"
                            value={item.comment || ''}
                            readOnly
                            borderless
                            multiline
                            autoAdjustHeight
                        />
                    </Columns.Column>
                </Columns>
                {item.type === EBoardCardType.HUNT && (
                    <>
                        <Text
                            variant="large"
                            block
                        >
                            <Icon iconName="CityNext" />
                            {' '}
                            Détails entreprise où chasser
                        </Text>
                        <Divider />
                        <Columns>
                            <Columns.Column size="one-third">
                                <TextField
                                    label="Téléphone fixe"
                                    value={/** @type {Hunt} */(item).landlinePhone || ''}
                                    readOnly
                                    borderless
                                />
                            </Columns.Column>
                            <Columns.Column size="one-third">
                                <BoardDetailPanelCvTemplateInput
                                    isReadOnly
                                    handler={/** @type {HuntsHandler} */(handler)}
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-third">
                                <TextField
                                    label="Code postal"
                                    value={/** @type {Hunt} */(item).zipCode || ''}
                                    readOnly
                                    borderless
                                />
                            </Columns.Column>
                            <Columns.Column size="one-third">
                                <TextField
                                    label="Ville"
                                    value={/** @type {Hunt} */(item).city?.name || ''}
                                    readOnly
                                    borderless
                                />
                            </Columns.Column>
                            <Columns.Column size="one-third">
                                <TextField
                                    label="Département"
                                    value={/** @type {Hunt} */(item).department?.name || ''}
                                    readOnly
                                    borderless
                                />
                            </Columns.Column>
                        </Columns>
                    </>
                )}
                {item?.interview?.isValidated && (
                    <>
                        <Text
                            variant="large"
                            block
                        >
                            <Icon iconName="ChatInviteFriend" />
                            {' '}
                            Compte rendu entretien
                        </Text>
                        <Divider />
                        <Columns>
                            <Columns.Column size="half">
                                <TextField
                                    label="Motivations"
                                    value={item?.interview?.motivation || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                            <Columns.Column size="half">
                                <TextField
                                    label="Formation"
                                    value={item?.interview?.formation || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                            {/* <Columns.Column size="half">
                                <TextField
                                    label="Pistes"
                                    value={item?.interview?.track || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column> */}
                        </Columns>
                        <Columns>
                            <Columns.Column size="half">
                                <TextField
                                    label="Rémunération actuelle (RA)"
                                    value={item?.interview?.currentSalary}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                            <Columns.Column size="half">
                                <TextField
                                    label="Rémunération souhaitée (RS)"
                                    value={item?.interview?.desiredSalary}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            {/* <Columns.Column size="half">
                                <TextField
                                    label="Découchage"
                                    value={item?.interview?.sleeping || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column> */}
                            <Columns.Column size="half">
                                <TextField
                                    label="Disponibilité"
                                    value={item?.interview?.availability || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                            <Columns.Column size="half">
                                <TextField
                                    label="Localisation et région envisagée pour déménagement"
                                    value={item?.interview?.location || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                        </Columns>
                        {/* <Columns>
                            <Columns.Column size="half">
                                <TextField
                                    label="Connaissances"
                                    value={item?.interview?.knowledge || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                        </Columns> */}
                        <Label>Aptitudes (Personnalité / Savoir être)</Label>
                        <Divider style={{ marginBottom: 0 }} />
                        <Columns>
                            <Columns.Column size="half">
                                <TextField
                                    label="Points d'appui"
                                    value={item?.interview?.aptitudePros || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                            <Columns.Column size="half">
                                <TextField
                                    label="Points de vigilance"
                                    value={item?.interview?.aptitudeCons || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                        </Columns>
                        <Label>Compétences (Adéquation par rapport au poste)</Label>
                        <Divider style={{ marginBottom: 0 }} />
                        <Columns>
                            <Columns.Column size="half">
                                <TextField
                                    label="Points d'appui"
                                    value={item?.interview?.professionnalSkillPros || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                            <Columns.Column size="half">
                                <TextField
                                    label="Points de vigilance"
                                    value={item?.interview?.professionnalSkillCons || ''}
                                    readOnly
                                    borderless
                                    multiline
                                    autoAdjustHeight
                                />
                            </Columns.Column>
                        </Columns>
                    </>
                )}
                <Text
                    variant="large"
                    block
                >
                    <Icon iconName="Comment" />
                    {' '}
                    Commentaires
                </Text>
                <Divider />
                {item.boardComments.map((comment, index) => (
                    <BoardDetailPanelComment
                        key={comment.boardCommentId}
                        item={comment}
                        onUpdate={newComment => {
                            const newValue = [...item.boardComments]
                            newValue[index] = newComment
                            const newItem = { ...item }
                            newItem.boardComments = newValue
                            onUpdate(newItem)
                        }}
                        me={me}
                        handler={handler}
                        onDelete={() => onUpdate({ ...item, boardComments: item.boardComments.filter((_, i) => i !== index) })}
                    />
                ))}
                <PrimaryButton
                    className={styles['board-detail-panel-add-comment-button']}
                    iconProps={{ iconName: 'CommentAdd' }}
                    onClick={() => onUpdate({
                        ...item,
                        boardComments: [
                            ...item.boardComments,
                            new BoardComment(/** @type {any} */({
                                createdByUser: me,
                                createdByUserId: me.userId,
                                status: getStatusString((item).boardColumnId),
                                createdAt: new Date(),
                                candidacyId: /** @type {Candidacy} */(item).candidacyId,
                                huntId: /** @type {Hunt} */(item).huntId,
                            }))],
                    })}
                    disabled={!!item.boardComments.find(x => !x.boardCommentId)}
                >
                    Ajouter
                </PrimaryButton>
                <br />
                <Text
                    variant="large"
                    block
                >
                    <Icon iconName="DocumentSet" />
                    {' '}
                    Documents annexes au CV
                </Text>
                <Divider />
                <BoardDetailPanelFiles
                    item={item}
                    handler={handler}
                    onUpdate={onUpdate}
                />
                <br />
                <Text
                    variant="large"
                    block
                >
                    <Icon iconName="HandsFree" />
                    {' '}
                    Actions
                </Text>
                <Divider />
                <div className={styles['board-detail-panel-buttons-container']}>
                    {[EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT].includes(item.boardColumnId) && !item.isRejected
                        && (
                            <PrimaryButton
                                onClick={() => {
                                    setIsBoardReCodingModalVisible(true)
                                }}
                                iconProps={{ iconName: 'UserSync' }}
                                title="Recoder manuellement les informations du candidat et rejeter la candidature"
                            >
                                Rejeter et recoder
                            </PrimaryButton>
                        )}
                    {item.boardColumnId !== EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT && (
                        <PrimaryButton
                            onClick={() => {
                                setIsVisible(false)
                                setIsUpsertBoardItemPanelOpen(true)
                            }}
                            iconProps={{ iconName: 'EditContact' }}
                            title="Modifier"
                        >
                            Modifier
                        </PrimaryButton>
                    )}
                    {[
                        EBoardColumn.CANDIDIDATE_INTERVIEW,
                        EBoardColumn.SENDING_CV,
                        EBoardColumn.CLIENT_CANDIDATE_INTERVIEW,
                        EBoardColumn.PLACED,
                    ].includes(item.boardColumnId)
                        && (
                            <PrimaryButton
                                onClick={() => {
                                    setIsVisible(false)
                                    setIsInterviewPanelOpen(true)
                                }}
                                iconProps={{ iconName: 'ChatInviteFriend' }}
                                title="Dossier candidat"
                            >
                                Dossier candidat
                            </PrimaryButton>
                        )}
                    {[
                        EBoardColumn.CANDIDIDATE_INTERVIEW,
                        EBoardColumn.SENDING_CV,
                        EBoardColumn.CLIENT_CANDIDATE_INTERVIEW,
                        EBoardColumn.PLACED,
                    ].includes(item.boardColumnId)
                        && (
                            <PrimaryButton
                                onClick={() => {
                                    downloadInterviewPdf()
                                }}
                                iconProps={{ iconName: 'Download' }}
                                title="Télécharger dossier candidat"
                                disabled={pdfInterviewStatus === Status.PENDING}
                            >
                                Télécharger dossier candidat
                            </PrimaryButton>
                        )}
                    {/* "Coding" is not used anymore, but will maybe be usefull in the futur, so I keep it here */}
                    {/* {[
                        EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT,
                    ].includes(item.boardColumnId)
                        && (
                            <PrimaryButton
                                onClick={() => {
                                    setIsBoardMissionCodingModalVisible(true)
                                }}
                                iconProps={{ iconName: 'UserSync' }}
                                title="Recoder automatiquement les informations du candidat par rapport à la mission"
                            >
                                Coder candidat
                            </PrimaryButton>
                        )} */}
                    {/* ATM, only used to de reject */}
                    {(![EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT].includes(item.boardColumnId) || item.isRejected) && (
                        <PrimaryButton
                            onClick={() => {
                                setIsRejectModalVisible(true)
                            }}
                            iconProps={{ iconName: item.isRejected ? 'CheckMark' : 'Cancel' }}
                            title={item.isRejected ? 'Dé-rejeter' : 'Rejeter'}
                        >
                            {item.isRejected ? 'Dé-rejeter' : 'Rejeter'}
                        </PrimaryButton>
                    )}
                    {([EBoardColumn.CALLED].includes(item.boardColumnId)) && (
                        <PrimaryButton
                            onClick={() => {
                                setIsCancelledModalVisible(true)
                            }}
                            iconProps={{ iconName: item.isCancelled ? 'CheckMark' : 'Cancel' }}
                            title={item.isCancelled ? 'Dé-annuler' : 'annulation'}
                        >
                            {item.isCancelled ? 'Dé-annuler' : 'Annulation'}
                        </PrimaryButton>
                    )}
                    {[EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT].includes(item.boardColumnId)
                        && (
                            <PrimaryButton
                                className={classNames(styles['is-red'])}
                                onClick={() => setIsDeleteModalVisible(true)}
                                iconProps={{ iconName: 'Delete' }}
                            >
                                Supprimer
                            </PrimaryButton>
                        )}
                </div>
            </Panel>
            <BoardInterviewPanel
                handler={handler}
                isVisible={isInterviewPanelOpen}
                setIsVisible={setIsInterviewPanelOpen}
                item={item}
                onUpdate={onUpdate}
            />
            <BoardRejectModal
                handler={handler}
                isVisible={isRejectModalVisible}
                setIsVisible={setIsRejectModalVisible}
                item={item}
                onUpdate={onUpdate}
            />
            <BoardCancelledModal
                handler={handler}
                isVisible={isCancelledModalVisible}
                setIsVisible={setIsCancelledModalVisible}
                item={item}
                onUpdate={onUpdate}
            />
            <BoardUpsertIBoardtemPanel
                item={item}
                isVisible={isUpsertBoardItemPanelOpen}
                setIsVisible={setIsUpsertBoardItemPanelOpen}
                param={param}
                handler={handler}
                onCreate={onCreate}
                onUpdate={onUpdate}
                missionId={item.missionId}
            />
            <BoardMissionCodingModal
                handler={handler}
                isVisible={isBoardMissionCodingModalVisible}
                setIsVisible={setIsBoardMissionCodingModalVisible}
                item={item}
                onUpdate={onUpdate}
            />
            <BoardReCodingModal
                handler={handler}
                isVisible={isBoardReCodingModalVisible}
                setIsVisible={setIsBoardReCodingModalVisible}
                item={item}
                param={param}
                onUpdate={onUpdate}
                isWithRejection
            />
            <BoardDeleteModal
                handler={handler}
                isVisible={isDeleteModalVisible}
                setIsVisible={setIsDeleteModalVisible}
                item={item}
                onDelete={onDelete}
            />
        </>
    )
}
