import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const commercialInformationColumns = [
    {
        fieldName: 'OperationManager',
        name: 'CR',
        type: Types.STRING,
        onRender: item => `${item['createdByUser.lastname'] || ''} ${item['createdByUser.firstname'] || ''}`,
    },
    {
        fieldName: 'Consultant',
        name: 'Consultant',
        type: Types.STRING,
        onRender: item => `${item['consultant.lastname'] || ''} ${item['consultant.firstname'] || ''}`,
    },
    {
        fieldName: 'client.name',
        name: 'Présent dans ATS',
        type: Types.STRING,
    },
    {
        fieldName: 'prospectName',
        name: 'Non présent dans ATS',
        type: Types.STRING,
    },
    {
        fieldName: 'department.name',
        name: 'Département',
        type: Types.STRING,
    },
    {
        fieldName: 'Contact',
        name: 'Nom/Prénom',
        type: Types.STRING,
        onRender: item => `${item.contactLastname || ''} ${item.contactFirstname || ''}`,
    },
    {
        fieldName: 'contactPhone',
        name: 'Téléphone',
        type: Types.STRING,
    },
    {
        fieldName: 'contactFunction',
        name: 'Fonction',
        type: Types.STRING,
    },
    {
        fieldName: 'comment',
        name: 'Commentaire',
        type: Types.STRING,
    },
    {
        fieldName: 'rdvDate',
        name: 'Entretien',
        type: Types.DATE,
    },
]

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
export const commercialInformationColumnsWithoutCR = commercialInformationColumns.filter(col => col.fieldName !== 'OperationManager')
export const commercialInformationColumnsWithoutConsultant = commercialInformationColumns.filter(col => col.fieldName !== 'Consultant')

export default commercialInformationColumns
